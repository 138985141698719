import React from "react";
import { Modal } from "antd";

const CancelBookingModal = (props) => {
  return (
    <>
      <Modal
        title="Cancel Booking"
        visible={true}
        onOk={props.handleSubmit}
        onCancel={props.closeModal}
        cancelText={"No, changed my mind"}
        okText={"Cancel Booking"}
        okButtonProps={{ loading: props.loading }}
      >
        Are you sure you want to cancel your booking?
      </Modal>
    </>
  );
};

export default CancelBookingModal;
