import React from "react";
import styled from "styled-components";
import { Image, Flex, Button } from "rebass";
import { Link } from "react-router-dom";
import checked from "../../assets/checked.svg";

const Home = styled(Button)`
  width: 150px;
  height: 42px;
  background: #f8ad43;
  border-radius: 4px;
  && {
    margin-top: 44px;
  }
`;

const Header = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1a1c20;
`;

const Body = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  width: 300px;
`;

const Container = styled(Flex)`
  padding: 100px 0px 250px 0px;
  flex-direction: column;
  align-items: center;
`;

const AddHotelSuccess = () => {
  return (
    <>
      <Container flexDirection="column">
        <Header>Success</Header>
        <Image src={checked} width={"84px"} mt={"44px"} mb={"24px"} />
        <Body>
          Your enquiry has been submitted successfully. We’ll get back to you
          shortly.
        </Body>
        <Link to="/">
          <Home>Home</Home>
        </Link>
      </Container>
    </>
  );
};

export default AddHotelSuccess;
