import React, { useState } from "react";
import styled from "styled-components";
import { Flex, Box } from "rebass";
import { Roller } from "react-awesome-spinners";
import { space } from "../styles/theme";
import axios from "axios";
import { useLocation, withRouter } from "react-router-dom";
import { Tabs } from "antd";
import Search from "./Search";
import Room from "./Room";
import Facilities from "./Facilities";
import { useHistory } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import moment from "moment";
import _ from "lodash";
import { notification } from "antd";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 831 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 831, min: 0 },
    items: 1,
  },
};

const Title = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  color: #181818;
`;

const Address = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  /* identical to box height */
`;

const Container = styled(Flex)`
  min-height: 100vh;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Image = styled.img`
  width: 400px;
  height: 303px;
  margin-right: 40px;
`;

const Body = styled(Flex)`
  flex-direction: column;
  padding: ${space[6]}px;

  @media screen and (max-width: 831px) {
    padding: 21px;
  }
`;

const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
`;

const openNotificationWithIcon = (type) => {
  notification[type]({
    message: "Error",
    description:
      "An Error occurred while trying to sign you in. Please, try again",
  });
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const { TabPane } = Tabs;

const Hotel = (props) => {
  const query = useQuery();
  const [isLoading, setLoading] = useState(false);
  const [hotel, setHotel] = useState(null);
  const [error, setError] = useState(false);
  // const query = props.location?.state;
  const history = useHistory();

  const checkin = query.get("checkin");
  const date = query.get("date");
  const duration = query.get("duration");
  const searchTerm = query.get("searchTerm");
  const id = props.match.params.id;
  const weekday = moment(new Date(date)).format("dddd")+'s';

  const checkinDetails = {
    checkin,
    weekday,
    date,
    searchTerm,
    id,
    duration,
    ...props.location?.state,
  };

  React.useEffect(() => {
    setLoading(true);
    axios
      .get(`https://quikstays-ng-prd.azurewebsites.net/api/v1/hotels/${id}`, {
        params: {
          checkin: checkin,
          date: date,
          duration: duration,
        },
      })
      .then((response) => {
        // response.data.hotel.available_rooms.filter(checkout => checkout.checkout > checkin);
        console.log(response.data.hotel)
        setHotel(response.data.hotel);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        openNotificationWithIcon("error");
      });
  }, [checkin, date, duration, id]);

  const handleHotelChange = (data, date) => {
    const search = data.search;
    const day = moment(new Date(date)).format("YYYY-MM-DD");
    const time = moment(new Date(date)).format("HH:mm");

    const hotelId = data.search._id;

    if (data.search.type === "Hotels") {
      history.push({
        pathname: `/hotels/${hotelId}`,
        search: `?checkin=${time}&date=${day}&duration=${duration}&searchTerm=${search.name}`,
        state: {
          hotel: search,
          datePickerDefaultValue: date || null,
        },
      });
    } else {
      history.push({
        pathname: `/hotels`,
        search: `?checkin=${time}&date=${day}&duration=${duration}&searchTerm=${search.name}`,
        state: {
          datePickerDefaultValue: date,
          location: search,
        },
      });
    }
  };

  return (
    <>
      <Container>
        {hotel?.hidden ? (
          <p style={{ margin: "auto", fontSize: "24px" }}>
            This accommodation is currently unavailable, it should be back on our site soonest.
          </p>
        ) : (
          <>
            {isLoading ? (
              <Box margin={"auto"}>
                <Roller />
              </Box>
            ) : (
              <>
                <Search
                  checkinDetails={checkinDetails}
                  handleHotelChange={handleHotelChange}
                />
                <Body>
                  <Box mb={space[6]}>
                    <Title>{hotel?.name}</Title>
                    <Address>{hotel?.input_address}</Address>
                  </Box>
                  {!_.isEmpty(hotel) && (
                    <Carousel responsive={responsive} infinite={true}>
                      {hotel?.images?.map((image, id) => (
                        <div key={id}>
                          <Image src={image.url} alt="" />
                        </div>
                      ))}
                    </Carousel>
                  )}
                  <Box mt={space[8]}>
                    <Tabs defaultActiveKey="1">
                      <TabPane tab="Rooms" key="1">
                         
                        {(() => {
                          if (hotel?.available_rooms?.length === 0) {
                            return(
                              <Box py={"80px"}>
                                <Text>
                                  The rooms for this hotel are currently sold out!!! Please check other available rooms on the site
                                </Text>
                              </Box>
                            )
          
                          }else if(checkin > '12:00' ){
                            return(
                               hotel?.available_rooms?.filter(checkout => checkout.checkout >= checkin).map((room, index) => (
                                <Room
                                  {...room}
                                  key={index}
                                  checkinDetails={checkinDetails}
                                  hotelImage={hotel.images[0].url}
                                />
                              )) 
                            )
             
                           

                          }else if(checkin >= '00:00' && checkin < '12:00'){
                            return(
                              hotel?.available_rooms?.filter(checkout => checkout.checkin <= checkin).map((room, index) => (

                                  <Room
                                    {...room}
                                    key={index}
                                    checkinDetails={checkinDetails}
                                    hotelImage={hotel.images[0].url}
                                  />
                                ))
                              )
               
                            
                          }
                        })()}
                        
                      
                      </TabPane>
                      <TabPane tab="Description" key="2">
                        {hotel?.description}
                      </TabPane>
                      <TabPane tab="Facilities" key="3">
                        <Facilities amenities={hotel?.amenities} />
                      </TabPane>
                      <TabPane tab="Reviews" key="4">
                        Reviews
                      </TabPane>
                    </Tabs>
                  </Box>
                </Body>
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default withRouter(Hotel);
