import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import styled from "styled-components";
import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

const SearchInput = styled(TextField)`
  background: #FAFAFA;
  border: 1px solid #D7D7D7;
  box-sizing: border-box;
  border-radius: 5px;
`

export const AutoComplete = (props) => {
  return (
    <>
      <Autocomplete
        style={props.style}
        freeSolo
        value={props.value}
        name="search"
        id="search"
        open={props.open}
        onOpen={props.onOpen}
        onClose={props.onClose}
        options={props.options}
        onChange={props.onChange}
        groupBy={(option) => option.type}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => (option.name ? option.name : "")}
        loading={props.loading}
        loadingText={'Looking for suggestions'}
        renderInput={(params) => (
          <SearchInput
            error={props.error}
            {...params}
            onChange={(ev) => {
              // dont fire API if the user delete or not entered anything
              if (ev.target.value !== "" || ev.target.value !== null) {
                props.onChangeHandle(ev.target.value);
              }
            }}
            variant="outlined"
            placeholder="Search for a hotel or location"
            InputProps={{
              ...params.InputProps,
              style: { height: props.height || null, padding: 2},
              endAdornment: (
                <React.Fragment>
                  {props.loading ? (
                    <CircularProgress size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
    </>
  )
}


export default AutoComplete
