import React from "react";
import logoSrc from "../assets/quikstays.svg";
import styled from "styled-components";
import { Image, Flex, Button } from "rebass";
import { space } from "./styles/theme";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Desktop, TabletOrMobile } from "../lib/Responsive";
import Hamburger from "./ui/Hamburger";
import HelpCenter from "./HelpCenter";
import { Avatar } from "antd";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const LogoContainer = styled(Link)`
  align-items: baseline;
`;

const NavLinks = styled(Link)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #181818;
  margin-right: ${space[4]}px;
`;

const ExternalLink = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #181818;
  margin-right: ${space[4]}px;
`;

const MobileExternalLink = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 28px;
  line-height: 19px;
  color: #181818;
`;

const Signup = styled(Button)`
  cursor: pointer;
  width: 107px;
  height: 42px;
  background: #f8ad43;
  border-radius: 4px;

  && {
    font-style: normal;
    color: #181818;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
`;

const MobileLinks = styled(Link)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 28px;
  line-height: 19px;
  color: #181818;
`;

const MobileUserName = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #181818;
  text-transform: capitalize;
  margin-left: 14px;
  font-weight: bold;
`;

const UserName = styled.p`
  margin-right: 16px;
  font-style: normal;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #181818;
  text-transform: capitalize;
`;

const HeaderContainer = styled(Flex)`
  padding: ${space[3]}px ${space[7]}px;
  justify-content: space-between;

  @media screen and (max-width: 831px) {
    padding: 21px;
    align-items: center;
  }
`;

const MenuLink = styled(Link)`
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  align-items: center;
  color: #000000;
  margin-bottom: 14px;
`;

const MenuWrapper = styled(Flex)`
  position: absolute;
  width: 250px;
  padding: 28px;
  border-radius: 5px;
  box-shadow: 0 7px 20px 0 rgba(6, 41, 102, 0.15);
  right: 45px;
  top: 80px;
  z-index: 4;
  background: white;
  flex-flow: column nowrap;
`;

const TransparentLayout = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: transparent;
`;

const Nav = (props) => {
  const [openMenu, setOpenMenu] = React.useState(false);
  return (
    <>
      <HeaderContainer>
        <LogoContainer to="/">
          <Image src={logoSrc} />
        </LogoContainer>
        <Desktop>
          {openMenu && (
            <>
              <TransparentLayout onClick={() => setOpenMenu(false)} />
              <MenuWrapper>
                <MenuLink to="/bookings">My Bookings</MenuLink>
                <MenuLink to="/logout">Logout</MenuLink>
              </MenuWrapper>
            </>
          )}
          <Flex alignItems={"center"}>
            <ExternalLink href="https://partner.quikstays.com">
              Hotel Partner Login
            </ExternalLink>
            <NavLinks to="/add-hotel">Add Hotel</NavLinks>
            <ExternalLink href="https://quickstays.azureedge.net">
              About Us
            </ExternalLink>
            <HelpCenter />
            {!props.user && (
              <>
                <NavLinks to="/login">Log in</NavLinks>
                <Link to="/signup">
                  <Signup>Sign up</Signup>
                </Link>
              </>
            )}
            {props.user && (
              <Flex
                onClick={() => setOpenMenu(!openMenu)}
                style={{ cursor: "pointer", alignItems: "center" }}
              >
                <UserName>{props.user.firstName}</UserName>
                <Avatar style={{ color: "white", backgroundColor: "#2657D6" }}>
                  {props.user.firstName[0].toUpperCase()}
                </Avatar>
                <ExpandMoreIcon />
              </Flex>
            )}
          </Flex>
        </Desktop>
        <TabletOrMobile>
          <Hamburger>
            {props.user && (
              <Flex alignItems={"center"} marginBottom={"28px"}>
                <Avatar style={{ color: "white", backgroundColor: "#2657D6" }}>
                  {props.user.firstName[0].toUpperCase()}
                </Avatar>
                <MobileUserName>{props.user.firstName}</MobileUserName>
              </Flex>
            )}

            {props.user && (
              <>
                <MobileLinks to="/bookings">My Bookings</MobileLinks>
              </>
            )}
            <MobileExternalLink href="https://partner.quikstays.com">
              Hotel Partner Login
            </MobileExternalLink>
            <MobileLinks to="/add-hotel">Add Hotel</MobileLinks>
            <MobileExternalLink href="https://quickstays.azureedge.net">
              About Us
            </MobileExternalLink>
            {!props.user && (
              <>
                <MobileLinks to="/login">Log in</MobileLinks>
                <MobileLinks to="/signup">Sign up</MobileLinks>
              </>
            )}
            {props.user && (
              <>
                <MobileLinks to="/logout">Logout</MobileLinks>
              </>
            )}
          </Hamburger>
        </TabletOrMobile>
      </HeaderContainer>
    </>
  );
};

export default withRouter(Nav);
