import burgaIcon from "../../assets/hamburger.svg";
import openBurger from "../../assets/x-slim.svg";
import * as React from "react";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { Button as RebassButton, Flex, Image } from "rebass";
import { Link } from "react-router-dom";
import SlideFade from "../../lib/SlideFade";
import styled, { css } from "styled-components";
import { space } from "../styles/theme";

const HamburgerIcon = styled(RebassButton)`
  background-color: transparent;
  background-image: url(${burgaIcon});
  background-position: center;
  background-repeat: no-repeat;
  height: 24px;
  width: 27px;
  cursor: pointer;
  padding: 10px;
  &:focus {
    box-shadow: none;
  }
`;
HamburgerIcon.displayName = "Hamburger";

const SlideOutNav = styled(SlideFade)`
  background: #f7fafd;
  box-shadow: -1px 1px 6px 0px #2c3c4c;
  position: fixed;
  width: auto;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  min-width: 260px;
  min-height: 100vh;
  height: 100%;
  top: 0;
  z-index: 10000;
  right: 0;
  padding-bottom: 100px;
`;

const CloseContainer = styled(Flex)`
  position: sticky;
  top: 0;
  z-index: 12;
  justify-content: flex-end;
`;

const CloseButton = styled(RebassButton)`
  background-color: transparent;
  padding: ${space[2]}px;
  cursor: pointer;
  &:focus {
    box-shadow: none;
  }
`;

const MenuLinkContainer = styled(Flex)`
  overflow: auto;
  padding-left: 21px;
  padding-top: 28px;
`;

const HamburgerMenu = (props) => {
  const [navOpen, toggleNav] = useState(false);

  return (
    <>
      <HamburgerIcon onClick={() => toggleNav(!navOpen)} />
      <SlideOutNav slideFrom={"right"} transitionOn={navOpen} timeout={200}>
        {() => (
          <div
            onClick={(e) => {
              toggleNav(false);
              e.nativeEvent.stopImmediatePropagation();
            }}
          >
            <CloseContainer>
              <CloseButton onClick={() => toggleNav(false)}>
                <Image size={space[2]} src={openBurger} />
              </CloseButton>
            </CloseContainer>
            <MenuLinkContainer flexDirection="column">
              {props.children}
            </MenuLinkContainer>
          </div>
        )}
      </SlideOutNav>
    </>
  );
};

export default withRouter(HamburgerMenu);
