import React from "react";
import { Flex, Button, Box, Image } from "rebass";
import styled from "styled-components";
import { Input, Form } from "antd";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import quickStays from "../../../assets/quikstays.svg";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { notification } from "antd";

const StyledInput = styled(Input)`
  &&& {
    height: 52px;
    background: #fafafa;
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 7px;
  }
`;

const Submit = styled(Button)`
  width: 250px;
  height: 50px;
  background: #f1b633;
  border-radius: 5px;
`;

const Title = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 14px;
`;

const Container = styled(Flex)`
  align-items: center;
  padding: 140px 0;
  flex-direction: column;

  @media screen and (max-width: 831px) {
    padding: 21px;
  }
`;

const StyledForm = styled.form`
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100%;
`;

const openNotificationWithIcon = (type) => {
  notification[type]({
    message: "Error",
    description:
      "An Error occurred while trying to sign you in. Please, try again",
  });
};

const Signup = () => {
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const { errors, handleSubmit, control } = useForm({
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post("https://quikstays-ng-prd.azurewebsites.net/api/v1/register", {
        firstName: data.firstname,
        lastName: data.lastname,
        phoneNo: data.phonenumber,
        email: data.email,
        password: data.password,
        passwordConfirmation: data.passwordconfirmation,
      })
      .then(function (response) {
        history.push("/login");
      })
      .catch((error) => {
        setLoading(false);
        openNotificationWithIcon("error");
      });
  };

  return (
    <>
      <Container>
        <Image src={quickStays} />
        <Title>Sign up</Title>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Box width={[1, 1, 3 / 10]}>
            <Form.Item validateStatus={errors.firstname ? "error" : "success"}>
              <Controller
                control={control}
                name="firstname"
                rules={{ required: "This is required message" }}
                render={(props) => (
                  <StyledInput
                    {...props}
                    placeholder="Firstname"
                    onChange={(e) => {
                      props.onChange(e);
                    }}
                  />
                )}
              />
            </Form.Item>
          </Box>
          <Box width={[1, 1, 3 / 10]}>
            <Form.Item validateStatus={errors.lastname ? "error" : "success"}>
              <Controller
                control={control}
                name="lastname"
                rules={{ required: "This is required message" }}
                render={(props) => (
                  <StyledInput
                    {...props}
                    placeholder="Lastname"
                    onChange={(e) => {
                      props.onChange(e);
                    }}
                  />
                )}
              />
            </Form.Item>
          </Box>
          <Box width={[1, 1, 3 / 10]}>
            <Form.Item
              validateStatus={errors.phonenumber ? "error" : "success"}
            >
              <Controller
                control={control}
                name={"phonenumber"}
                defaultValue=""
                rules={{ required: "This is required message" }}
                render={({ onChange }) => {
                  return (
                    <PhoneInput
                      inputStyle={{ width: "100%", height: "52px" }}
                      containerStyle={{
                        width: "100%",
                        height: "52px",
                        marginBottom: "7px",
                      }}
                      country={"ng"}
                      preferredCountries={["ng", "us", "gb"]}
                      onChange={(v) => onChange(v)}
                      inputProps={{ name: "phonenumber" }}
                    />
                  );
                }}
              />
            </Form.Item>
          </Box>
          <Box width={[1, 1, 3 / 10]}>
            <Form.Item validateStatus={errors.email ? "error" : "success"}>
              <Controller
                control={control}
                name="email"
                rules={{ required: "This is required message" }}
                render={(props) => (
                  <StyledInput
                    {...props}
                    placeholder="Email"
                    onChange={(e) => {
                      props.onChange(e);
                    }}
                  />
                )}
              />
            </Form.Item>
          </Box>
          <Box width={[1, 1, 3 / 10]}>
            <Form.Item validateStatus={errors.password ? "error" : "success"}>
              <Controller
                control={control}
                name="password"
                id="password"
                rules={{ required: "This is required message" }}
                render={(props) => (
                  <StyledInput.Password
                    {...props}
                    placeholder="Password"
                    style={{ height: 52, marginBottom: 7 }}
                    onChange={(e) => {
                      props.onChange(e);
                    }}
                  />
                )}
              />
            </Form.Item>
          </Box>
          <Box width={[1, 1, 3 / 10]}>
            <Form.Item
              validateStatus={errors.passwordconfirmation ? "error" : "success"}
            >
              <Controller
                control={control}
                name="passwordconfirmation"
                id="passwordconfirmation"
                rules={{ required: "This is required message" }}
                render={(props) => (
                  <StyledInput.Password
                    {...props}
                    placeholder="Confirm password"
                    style={{ height: 52 }}
                    onChange={(e) => {
                      props.onChange(e);
                    }}
                  />
                )}
              />
            </Form.Item>
          </Box>
          <Submit loading={loading}>Register now</Submit>
          {!!Object.entries(errors).length && (
            <Box mt={"7px"} color="red">
              Kindly fill the appropriate text fields
            </Box>
          )}
        </StyledForm>
      </Container>
    </>
  );
};

export default Signup;
