import React from "react";
import styled from "styled-components";
import { Flex, Image, Box } from "rebass";
import moment from "moment";
import hotel from "../../assets/booking-hotel-dummy.png";
import date from "../../assets/calendar-booking.svg";
import room from "../../assets/room.svg";
import time from "../../assets/time-booking.svg";
import { Button } from "antd";
import TimeModal from "./TimeModal";
import CancelBookingModal from "./CancelBookingModal";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { removeTimezone } from "./index";

const Container = styled(Flex)`
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px #f8f8f8;
  border-radius: 8px;
  padding: 7px;
`;

const BodyText = styled(Box)`
  flex-direction: column;
  && {
    margin-left: 21px;
  }
  padding: 0px 24px 24px 7px;

  @media screen and (max-width: 831px) {
    && {
      margin-left: 0px;
    }
    padding: 24px 0;
  }
`;

const Hotel = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1a1c20;
  margin-bottom: 4px;
`;

const Address = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: rgba(26, 28, 32, 0.7);
  margin-bottom: 16px;
`;

const Price = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #092774;
  margin-top: 42px;
`;

const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #181818;
  margin-right: 16px;
`;

const BookButton = styled(Button)`
  width: 121px;
  height: 42px;
  background: #f8ad43;
  border-radius: 4px;
  margin-top: 21px;
  margin-right: 21px;
`;

const BookingCard = (props) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("quikstays-token")}`,
    },
  };
  const [loading, setIsLoading] = React.useState(false);
  const history = useHistory();

  const [modal, setModal] = React.useState(false);
  const [cancelModal, setCancelModal] = React.useState(false);
  const startTime = moment
    .utc(new Date(props.booking.startTime))
    .format("YYYY-MM-DD");

  const isTomorrowOrAfter = moment(startTime).isSameOrAfter(
    moment().add(1, "days").format("YYYY-MM-DD")
  );

  const isElevenThirtySameDay =
    moment(removeTimezone(props.booking.startTime)).format(
      "YYYY-MM-DD HH:mm"
    ) === moment().endOf("day").subtract(29, "m").format("YYYY-MM-DD HH:mm");

  const handleSubmit = (date) => {
    const time = moment(new Date(date)).format("HH:mm");
    const day = moment(new Date(date)).format("YYYY-MM-DD");

    history.push({
      pathname: `/hotels/${props.booking.hotel._id}`,
      search: `?checkin=${time}&date=${day}&duration=${3}&searchTerm=${
        props.booking.hotel.name
      }`,
      state: {
        datePickerDefaultValue: date,
      },
    });
    setModal(false);
  };

  const handleCancel = () => {
    setIsLoading(true);
    axios
      .post(
        `https://quikstays-ng-prd.azurewebsites.net/api/v1/cancel-booking/${props.booking._id}`,
        {},
        config
      )
      .then(function (response) {
        if (response.status === 200) {
          setIsLoading(false);
          setCancelModal(false);
        props.deleteBooking(props.booking._id);
        window.scrollTo(0, 0);
        }

      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setCancelModal(false);
      });
  };

  return (
    <>
      {modal && (
        <TimeModal
          handleCancel={() => setModal(false)}
          handleSubmit={(data) => handleSubmit(data)}
        />
      )}
      {cancelModal && (
        <CancelBookingModal
          closeModal={() => setCancelModal(false)}
          handleSubmit={handleCancel}
          loading={loading}
        />
      )}
      <Container flexDirection={["column", "column", "row"]}>
        <Box>
          <Image
            src={hotel}
            sx={{
              width: ["100%", "100%", null],
              height: "100%",
              borderRadius: 4,
            }}
          />
        </Box>
        <BodyText>
          <Hotel>{props.booking.hotel?.name}</Hotel>
          <Address>{props.booking.hotel?.input_address}</Address>
          <Box>
            <Flex mb={"4px"}>
              <Text>
                <Image src={room} mr={"7px"} />
                {props.booking.room?.type}
              </Text>
              <Text>
                <Image src={date} mr={"7px"} />{" "}
                {moment
                  .utc(removeTimezone(props.booking.startTime))
                  .format("DD-MM-YYYY")}
              </Text>
            </Flex>
            <Text>
              <Image src={time} mr={"7px"} />
              {moment
                .utc(
                  removeTimezone(props.booking.startTime),
                  "ddd MMM DD YYYY HH:mm:ss"
                )
                .format("HH-mm")}{" "}
              -{" "}
              {moment
                .utc(
                  removeTimezone(props.booking.endTime),
                  "ddd MMM DD YYYY HH:mm:ss"
                )
                .format("HH-mm")}
            </Text>
            <Price>₦{Math.floor(props.booking.amountPaid)}</Price>
          </Box>
        </BodyText>
      </Container>
      <Flex>
        <BookButton onClick={() => setModal(true)}>Book Again</BookButton>

        {(isTomorrowOrAfter || (isElevenThirtySameDay && !props.ongoing)) && (
          <BookButton onClick={() => setCancelModal(true)}>
            Cancel Booking
          </BookButton>
        )}
      </Flex>
    </>
  );
};

export default BookingCard;
