import React from "react";
import { Flex, Box } from "rebass";
// import HotelListingsData from './data'
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Pagination } from "antd";
import "antd/dist/antd.css";

const StyledImage = styled.img`
  width: 100%;
  height: 154px;
  border-radius: 6px;
`;

const Name = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #282828;
`;

const Address = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #282828;
`;

const Price = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px
  color: #092774;
`;

const Container = styled(Flex)`
  flex: 1;
  flex-direction: column;
`;

const Listings = (props) => {
  const desktopWidth = props.toggleMap ? 1 / 3 : 1 / 4;
  const tabletWidth = props.toggleMap ? 1 / 2 : 1 / 3;
  const pageSize = props.toggleMap ? 6 : 8;
  const [pageDetails, setPastPageDetails] = React.useState({
    totalPage: 0,
    current: 1,
    minIndex: 0,
    maxIndex: 0,
  });

  const durations = {
    3: "three_hrs",
    6: "six_hrs",
    9: "nine_hrs",
    12: "twelve_hrs",
  };

  const handleChange = (page) => {
    window.scrollTo(0, 0);
    setPastPageDetails({
      ...pageDetails,
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
  };

  React.useEffect(() => {
    setPastPageDetails({
      ...pageDetails,
      totalPage: props.hotels.length / pageSize,
      minIndex: 0,
      maxIndex: pageSize,
    });
  }, []);

  return (
    <Container px={["21px", "21px", "60px"]} mt={"60px"}>
      <Flex flexWrap="wrap">
        {props.hotels.map((hotel, index) => {
          const price = hotel.startingPrice[durations[props.checkinDetails.duration]];
          return (
            index >= pageDetails.minIndex &&
            index < pageDetails.maxIndex && (
              <Box
                key={index}
                width={[1, 1 / 2, tabletWidth, desktopWidth]}
                justifyContent="space-between"
                px={[0, "14px", "14px"]}
                pb={"14px"}
              >
                <Link
                  to={{
                    pathname: `/hotels/${hotel._id}?checkin=${props.checkinDetails.checkin}&date=${props.checkinDetails.date}&duration=${props.checkinDetails.duration}&searchTerm=${props.checkinDetails.searchTerm}`,
                    state: {
                      hotel: hotel,
                      datePickerDefaultValue: props.checkinDetails.datePickerDefaultValue || null,
                    },
                  }}
                >
                  <StyledImage src={hotel.images[0].url} />
                  <Flex mt={"7px"} flexDirection={"column"}>
                    <Flex>
                      <Name>{hotel.name}</Name>&nbsp;
                      <Address>
                        {hotel.formatted_address.city +
                          " " +
                          hotel.formatted_address.state}
                      </Address>
                      &nbsp;
                    </Flex>
                    <Flex>
                      <Price>{price ? `₦${price}` : `N/A`}</Price>&nbsp;
                      <p style={{ color: "black" }}>
                        {" "}
                        for {props.checkinDetails.duration} hours
                      </p>
                    </Flex>
                  </Flex>
                </Link>
              </Box>
            )
          );
        })}
      </Flex>
      {props.hotels.length !== 0 && (
        <Pagination
          pageSize={pageSize}
          current={pageDetails.current}
          total={props.hotels.length}
          onChange={handleChange}
          style={{ marginTop: "20px" }}
        />
      )}
    </Container>
  );
};

export default withRouter(Listings);
