import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {
    font-family: Sans-Serif, Open-Sans, Helvetica, Circular Std;
    overflow-x: hidden;
    position: relative;
    height: auto;
  }

  p {
    margin: 0;
  }
`;

export default GlobalStyle;
