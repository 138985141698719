import React from 'react';
import styled from 'styled-components';
import {Image, Flex, Box, Text} from 'rebass';
import { HowItWorksData } from '../../data/howItWorksData';
import {space} from '../styles/theme';

const ImageContainer = styled(Flex)`
  height: 52px;
  width: 52px;
`

const HowItWork = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 46px;
  color: #181818;
  text-align: center;
`

const Container = styled(Flex)`
  justify-content: space-between;
`

const Title = styled(Text)`
  && {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    color: #181818;
    margin-top: 14px;
  }
`

const Body = styled(Text)`
  && {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #434F59;
    margin-top: 14px;
  }
`
const FeatureContainer = styled(Flex)`
  margin-top: 14px;
`

const HowItWorks = () => {
  return <Box ml={['21px', '21px', '100px']} mr={['21px', '21px', '100px']} mt={space[12]} mb={space[8]}>
    <HowItWork>How Quikstays work</HowItWork>
    <Container mt={space[6]} flexWrap='wrap'>
      {HowItWorksData.map(howItWork => {
        return (
          <FeatureContainer
            flexDirection='column'
            alignItems='center'
            key={howItWork.title}
            width={[1,1,1/3]}
            pt={[space[8], space[8], 0]}
            >
            <ImageContainer><Image src={howItWork.logoSrc}/></ImageContainer>

            <Title>{howItWork.title}</Title>
            <Body>{howItWork.feature}</Body>
          </FeatureContainer>
        );
      })}
    </Container>
  </Box>
};

export default HowItWorks;
