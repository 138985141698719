import { Menu, Dropdown } from 'antd';
import React from 'react';
import { Link } from "react-router-dom";
import styled  from 'styled-components';
import { space } from "../styles/theme";

const NavLink = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #181818;
  margin-right: ${space[4]}px;
`;

const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/customer-faqs">
        Customer FAQs
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/hotel-partner-faqs">
        Hotel Partner FAQs
      </Link>
    </Menu.Item>
  </Menu>
);


const HelpCenter = () => {

  return (
    <Dropdown overlay={menu}>
      <NavLink className="ant-dropdown-link"  onClick={e => e.preventDefault()}>
        Help & Support
      </NavLink>
    </Dropdown>
  )
}

export default HelpCenter
