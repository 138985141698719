import { useMediaQuery } from 'react-responsive'

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 831 })
  return isDesktop ? children : null
}

export const TabletOrMobile = ({ children }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 830 })
  return isTabletOrMobile ? children : null
}
