import React from "react";
import { Flex, Box, Image } from "rebass";
import styled from "styled-components";
import { withRouter, useHistory, Link } from "react-router-dom";
import { Input } from "@rebass/forms";
import { useForm, Controller } from "react-hook-form";
import Paystack from "./Paystack";
import paystack from "../../assets/paystack.svg";
import LoadingOverlay from "react-loading-overlay";
import { Roller } from "react-awesome-spinners";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Checkbox, Button } from "antd";
import { notification } from "antd";

const BookingDetails = styled(Flex)`
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px #f8f8f8;
  border-radius: 8px;
  height: fit-content;

  && {
    margin-right: 28px;
  }

  @media screen and (max-width: 831px) {
    width: 100%;
    flex-direction: column;
    && {
      margin-bottom: 14px;
    }
  }
`;

const UserPanel = styled(Flex)`
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px #f8f8f8;
  border-radius: 8px;
  padding: 21px;
`;

const StyledImage = styled(Image)`
  && {
    height: 400px;

    @media screen and (max-width: 831px) {
      height: 250px;
    }
  }
`;

const Container = styled(Flex)`
  padding: 60px;
  justify-content: space-between;

  @media screen and (max-width: 831px) {
    padding: 20px;
    flex-direction: column;
  }
`;

const StyledInput = styled(Input)`
  &&& {
    height: 50px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    border-radius: 6px;

    @media screen and (max-width: 831px) {
      margin-bottom: 14px;
    }
  }
`;

const Header = styled(Flex)`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1a1c20;
`;

const Submit = styled(Button)`
  width: 250px;
  height: 50px;
  background: #f1b633;
  border-radius: 5px;
  margin: auto;
  margin-top: 28px;

  @media screen and (max-width: 831px) {
    width: 100%;
  }
`;

const Discount = styled(Button)`
  width: 250px;
  height: 40px;
  background: #f1b633;
  border-radius: 5px;
  margin: 0px;

  @media screen and (max-width: 831px) {
    width: 100%;
  }
`;

const DiscountInput = styled(Input)`
  &&& {
    height: 40px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 14px;

    @media screen and (max-width: 831px) {
      margin-bottom: 14px;
    }
  }
`;

const Hotel = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1a1c20;
`;

const Room = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #1a1c20;
  margin-top: 7px;
`;

const Divider = styled.div`
  width: 100%;
  margin-top: 21px;
  margin-bottom: 21px;
  border: 0.5px solid #ebe6df;
`;

const Value = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #181818;
`;

const Total = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #f0a500;
`;

const TotalField = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #787885;
`;

const Booking = (props) => {
  let history = useHistory();
  const [userDetails, setUserDetails] = React.useState(null);
  const [buttonState, setButtonState] = React.useState(true);
  const [discount, setDiscount] = React.useState(null);

  const openNotificationWithIcon = (type) => {
    if (type === "success") {
      notification[type]({
        message: "Success",
        placement: "topLeft",
        description: "Discount code applied successfully",
      });
    } else {
      notification[type]({
        placement: "topLeft",
        message: "Error",
        description: "Invalid discount code",
      });
    }
  };

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("quikstays-token")}`,
    },
  };

  React.useEffect(() => {
    if (!props.user) {
      return;
    }
    setIsLoading(true);
    axios
      .get(`https://quikstays-ng-prd.azurewebsites.net/api/v1/user`, config)
      .then((response) => {
        const { firstName, lastName, phoneNo, email } = response.data.user;
        setUserDetails({
          firstName: firstName,
          lastName: lastName,
          phoneNo: phoneNo,
          email: email,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  const { register, control, errors, handleSubmit } = useForm();

  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
  } = useForm();
  const [allValues, setAllValues] = React.useState({
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
  });
  const [modal, setModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDiscountLoading, setIsDiscountLoading] = React.useState(false);
  const [discountCode, setDiscountCode] = React.useState("");
  const query = props.location.state;

  const handleDiscount = async (data) => {
    setDiscountCode(data.discountcode);
    setIsDiscountLoading(true);
    try {
      const response = await axios.get(
        `https://quikstays-ng-prd.azurewebsites.net/api/v1/check-discount`,
        {
          params: {
            code: data.discountcode,
            amount: query.price,
          },
        }
      );

      if (response.status === 200) {
        setIsDiscountLoading(false);
        setDiscount(response.data.data.discounted_amount);
        openNotificationWithIcon("success");
      }
    } catch (error) {
      setIsDiscountLoading(false);
      openNotificationWithIcon("error");
    }
  };

  const handleBooking = (data) => {
    setAllValues({
      ...allValues,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phonenumber: `+${data.phonenumber}`,
    });
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleSuccess = async (ref) => {
    setModal(false);
    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://quikstays-ng-prd.azurewebsites.net/api/v1/bookings",
        {
          reference: ref.reference,
          room_id: query.room_id,
          date: query.date,
          checkin: query.time,
          duration: query.duration,
          contact: {
            firstName: allValues.firstname,
            lastName: allValues.lastname,
            phoneNo: allValues.phonenumber,
            email: allValues.email,
          },
          discount_code: discountCode,
        }
      );
      if (response.status === 200) {
        setIsLoading(false);
        history.push("/booking-success");
      }
    } catch (error) {
      setIsLoading(false);
      history.push("/failure");
    }
  };

  return (
    <LoadingOverlay active={isLoading} spinner={<Roller />}>
      <Container>
        {modal && (
          <Paystack
            email={allValues.email}
            amount={discount || query.price}
            handleSuccess={(ref) => handleSuccess(ref)}
            closeModal={closeModal}
          />
        )}
        <BookingDetails width={[1, 1, 4 / 10]}>
          <StyledImage src={query.image} />
          <Flex flexDirection={"column"} padding={"21px"}>
            <Hotel>{query.hotel}</Hotel>
            <Room>
              {query.type} • {query.date} • {query.duration} hours
            </Room>
            <Box mt={"7px"}>
              <Flex justifyContent={"space-between"}>
                <Box>Check-in time</Box>
                <Value>{query.time}</Value>
              </Flex>
              <Flex justifyContent={"space-between"}>
                <Box>Number of rooms</Box>
                <Value>{query.number}</Value>
              </Flex>
            </Box>
            <Flex justifyContent={"space-between"}>
              <Box>Amount for selected hours</Box>
              <Value>₦{query.price}</Value>
            </Flex>

            <form onSubmit={handleSubmit2(handleDiscount)}>
              <Flex alignItems={"baseline"}>
                <DiscountInput
                  placeholder="Discount Code"
                  id="discountcode"
                  type="discountcode"
                  name="discountcode"
                  ref={register2({ required: true })}
                  mr={"14px"}
                />

                <Discount
                  htmlType="submit"
                  mt={"28px"}
                  mx={"auto"}
                  type="submit"
                  loading={isDiscountLoading}
                >
                  Apply code
                </Discount>
              </Flex>
            </form>

            <Divider />
            <Flex justifyContent={"space-between"}>
              <TotalField>Total Amount</TotalField>
              <Total>₦{discount || query.price}</Total>
            </Flex>
          </Flex>
        </BookingDetails>
        <Box width={[1, 1, 6 / 10]}>
          <form onSubmit={handleSubmit(handleBooking)}>
            <UserPanel>
              <Header>Enter your information</Header>
              <Flex
                mt={"28px"}
                flexDirection={["column", "column", "row"]}
                justifyContent={"space-between"}
              >
                <StyledInput
                  placeholder="First Name"
                  id="firstname"
                  type="firstname"
                  name="firstname"
                  ref={register({ required: true })}
                  mr={"14px"}
                  defaultValue={userDetails?.firstName || null}
                />
                <StyledInput
                  placeholder="Last Name"
                  id="lastname"
                  type="lastname"
                  name="lastname"
                  ref={register({ required: true })}
                  defaultValue={userDetails?.lastName || null}
                />
              </Flex>
              <Flex
                flexDirection={["column", "column", "row"]}
                mt={[0, 0, "14px"]}
                justifyContent={"space-between"}
              >
                <StyledInput
                  placeholder="Email address"
                  id="email"
                  type="email"
                  name="email"
                  mr={"14px"}
                  ref={register({
                    required: "Required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Enter a valid Email address",
                    },
                  })}
                  defaultValue={userDetails?.email || null}
                />
                <Controller
                  control={control}
                  name={"phonenumber"}
                  rules={{ required: "This is required message" }}
                  render={({ onChange }) => {
                    return (
                      <PhoneInput
                        inputStyle={{ width: "100%", height: "50px" }}
                        containerStyle={{
                          width: "100%",
                          height: "50px",
                        }}
                        country={"ng"}
                        preferredCountries={["ng", "us", "gb"]}
                        onChange={(v) => onChange(v)}
                        inputProps={{ name: "phone" }}
                      />
                    );
                  }}
                />
              </Flex>
            </UserPanel>

            <UserPanel mt={"14px"}>
              <Flex flexDirection={"column"}>
                <Box>
                  <Header>Make payment</Header>
                </Box>
                <Flex justifyContent={"space-between"} my={"28px"}>
                  <Box>
                    <Header>Paystack</Header>
                    Safe payment online via Paystack
                  </Box>
                  <Image src={paystack} />
                </Flex>
              </Flex>
              <Checkbox onChange={() => setButtonState(!buttonState)}>
                I have read and accepted the{" "}
                <Link to="/terms-and-conditions">terms and conditions</Link>
              </Checkbox>
              <Submit
                htmlType="submit"
                mt={"28px"}
                mx={"auto"}
                disabled={buttonState}
                type="submit"
              >
                Pay now
              </Submit>
              {!!Object.entries(errors).length && (
                <Box mt={"14px"} color="red" textAlign={"center"}>
                  Kindly fill the appropriate text fields
                </Box>
              )}
            </UserPanel>
          </form>
        </Box>
      </Container>
    </LoadingOverlay>
  );
};

export default withRouter(Booking);
