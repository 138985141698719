import React from 'react'
import heroImage from '../../assets/white-pillow.png'
import {Flex, Image} from 'rebass'
import Search from './Search'
import styled from 'styled-components'

const Container = styled(Flex)`

  @media screen and (max-width: 831px) {
    flex-direction: column-reverse
  }
`

const Hero = () => {
  return (
    <>
      <Container>
        <Search/>
        <Image src={heroImage}/>
      </Container>
    </>
  );
}

export default Hero
