import React from 'react'
import styled from 'styled-components'
import 'antd/dist/antd.css';
import { Flex, Button } from 'rebass';
import { space } from "../styles/theme";
import moment from 'moment';
import { useForm, Controller } from "react-hook-form";
import AutoComplete from '../ui/AutoComplete';
import axios from 'axios';
import ReactDatePicker from 'react-datepicker';
import { useMediaQuery } from "react-responsive";
import DatePickerInput from '../LandingPage/DatePickerInput'

const SearchContainer = styled(Flex)`
  border: 1px #D7D7D7;
  border-style: solid;
  padding: 10px 60px;
  background: #FAFAFA;
  && {
    margin-bottom:  ${space[3]}px;
  }

  @media screen and (max-width: 831px) {
    padding: 21px;
  }
`
const SearchButton = styled(Button)`
  && {
    height: 40px;
    background: #FAFAFA;
    border: 1px solid #D7D7D7;
    box-sizing: border-box;
    border-radius: 5px;
    width: 132px;
    margin-left: 16px;
    cursor: pointer;
    background: #F8AD43;

    @media screen and (max-width: 831px) {
      margin-left: 0px;
      width: 100%;
    }
  }
`

const StyledForm = styled.form`
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: center;

  @media screen and (max-width: 831px) {
    height: 100%;
    flex-direction: column;
  }
`

const AutoCompleteContainer = styled.div`
  width: 200px;
  margin-right: 16px;

  @media screen and (max-width: 831px) {
    margin-bottom: 14px;
    width: 100%;
  }
`


const StyledReactDatePicker = styled(ReactDatePicker)`
  && {
    margin-right: 16px;
    background: #fafafa;
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 14px;
  }
`;

const Picker = styled.div`
  width: 250px;

  @media screen and (max-width: 831px) {
    width: 100%;
  }
`;

const Search  = ({checkinDetails, handleHotelChange}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0;

  const [date, setDate] = React.useState(checkinDetails?.datePickerDefaultValue);
  const calculateMinTime = (date) => {
    let isToday = moment(date).isSame(moment(), "day");
    if (isToday) {
      let currentTime = moment(new Date()).toDate();
      return currentTime;
    }
    return moment().startOf("day").toDate(); // set to 12:00 am today
  };

  const [minTime, setMinTime] = React.useState(calculateMinTime(new Date()));

  const handleDateChange = (date) => {
    setDate(date);
    setMinTime(calculateMinTime(date));
  };

  const isTabletOrMobile = useMediaQuery({ maxWidth: 830 });


  const preloadedValues = {
    search: checkinDetails.hotel
  }

  const onChangeHandle = async (value) => {
    let allSearchList = [];

    await axios.get(`https://quikstays-ng-prd.azurewebsites.net/api/v1/search?searchTerm=${value}`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if (res.data.hotels?.length > 0) {
          for (let j = 0; j < res.data.hotels.length; j++) {
            allSearchList.push({
              _id: res.data.hotels[j]._id,
              type: 'Hotels',
              name: res.data.hotels[j].name
            });
          }
        }

        if (res.data.locations?.length > 0) {
          for (let i = 0; i < res.data.locations.length; i++) {
            allSearchList.push({
              _id: i,
              type: 'Locations',
              name: res.data.locations[i]
            });
          }
        }
        setOptions(allSearchList)
      })
      .catch(error => {
        console.log(error)
      })
  }


  const onSubmit = (data) => {
    handleHotelChange(data, date)
  }

  const { handleSubmit, errors, control} = useForm({
    mode: 'onSubmit',
    defaultValues: preloadedValues
  });

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <>
      <SearchContainer justifyContent='center' alignItems='baseline'>
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <AutoCompleteContainer>
              <Controller
                control={control}
                name="search"
                id="search"
                rules={{ required:  "This is required message" }}
                render={(props) => (
                  <AutoComplete
                    value={props.value}
                    height={40}
                    open={open}
                    onChange={(e, v) => {
                      props.onChange(v)
                    }}
                    onOpen={() => setOpen(true)}
                    error={!!errors.search}
                    onClose={() => setOpen(false)}
                    onChangeHandle={onChangeHandle}
                    loading={loading}
                    options={options.sort((a, b) =>  a.type === b.type ? 0 : a.type > b.type ? 1 : -1)}
                  />
                )}
              />
            </AutoCompleteContainer>
            <Picker>
              <StyledReactDatePicker
                withPortal={isTabletOrMobile}
                customInput={<DatePickerInput />}
                onChange={handleDateChange}
                selected={date}
                minDate={new Date()}
                minTime={minTime}
                maxTime={moment().endOf("day").toDate()}
                timeIntervals={30}
                showTimeSelect
                placeholderText="Select check-in date and time"
                dateFormat="MMMM dd yyyy, h:mm a"
              />
            </Picker>
            <SearchButton type="submit">Search</SearchButton>
          </StyledForm>
      </SearchContainer>
    </>
  )
}

export default Search
