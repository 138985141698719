import React, { useState } from "react";
import { Modal, Button } from "antd";
import ReactDatePicker from "react-datepicker";
import { useMediaQuery } from "react-responsive";
import DatePickerInput from "../LandingPage/DatePickerInput";
import moment from "moment";
import styled from "styled-components";

const StyledReactDatePicker = styled(ReactDatePicker)`
  && {
    background: #fafafa;
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    border-radius: 5px;
    height: 52px;
    padding: 14px;
    width: 100%;
  }
`;

const TimeModal = (props) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 830 });
  const [date, setDate] = React.useState(null);

  const calculateMinTime = (date) => {
    let isToday = moment(date).isSame(moment(), "day");
    if (isToday) {
      let nowAddOneHour = moment(new Date()).toDate();
      return nowAddOneHour;
    }
    return moment().startOf("day").toDate(); // set to 12:00 am today
  };

  const [minTime, setMinTime] = React.useState(calculateMinTime(new Date()));

  const handleDateChange = (date) => {
    setDate(date);
    setMinTime(calculateMinTime(date));
  };

  const isModalDisabled = !date;

  return (
    <>
      <Modal
        title="Book Again"
        visible={true}
        onOk={() => props.handleSubmit(date)}
        onCancel={props.handleCancel}
        okText={"Check availability"}
        okButtonProps={{ disabled: isModalDisabled }}
      >
        <StyledReactDatePicker
          withPortal={isTabletOrMobile}
          customInput={<DatePickerInput />}
          onChange={handleDateChange}
          name="dater"
          wrapperClassName="datePicker"
          selected={date}
          minDate={new Date()}
          minTime={minTime}
          maxTime={moment().endOf("day").toDate()} // set to 23:59 pm today
          timeIntervals={30}
          showTimeSelect
          placeholderText="Select check-in date and time"
          dateFormat="MMMM dd yyyy, h:mm a"
        />
      </Modal>
    </>
  );
};

export default TimeModal;
