import React from 'react'
import styled from 'styled-components'
import 'antd/dist/antd.css';
import { Flex, Button } from 'rebass';
import { Switch } from 'antd';
import moment from 'moment';
import { Radio } from 'antd';
import { useForm, Controller } from "react-hook-form";
import AutoComplete from '../ui/AutoComplete';
import axios from 'axios'
import { Desktop } from "../../lib/Responsive";
import ReactDatePicker from 'react-datepicker';
import { useMediaQuery } from "react-responsive";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerInput from '../LandingPage/DatePickerInput'
import "./datepicker.css";

const SearchButton = styled(Button)`
  && {
    height: 40px;
    background: #FAFAFA;
    border: 1px solid #D7D7D7;
    box-sizing: border-box;
    border-radius: 5px;
    width: 132px;
    margin-left: 16px;
    cursor: pointer;
    background: #F8AD43;

    @media screen and (max-width: 831px) {
      width: 100%;
      margin-left: 0px;
      margin-top: 14px;
    }
  }
`

const SearchContainer = styled(Flex)`
  border: 1px #D7D7D7;
  border-style: solid;
  padding: 10px 60px;
  background: #FAFAFA;

  @media screen and (max-width: 1024px) {

  }

  @media screen and (max-width: 831px) {
    padding: 20px;
  }
`

const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-right: 6px;
  line-height: 17px;
  text-align: right;
  color: #282828;
  white-space: nowrap;
`

const StyledRadioGroup = styled(Radio.Group)`
  margin-left: 16px;

  @media screen and (max-width: 831px) {
    margin-left: 0px;
  }
`

const StyledRadio = styled(Radio.Button)`
  height: 40px;
  line-height: 45px;
`

const StyledForm = styled.form`
  display: flex;
  height: 40px;
  width: 100%;

  @media screen and (max-width: 831px) {
    height: 100%;
    flex-direction: column;
  }
`

const AutoCompleteContainer = styled.div`
  width: 200px;
  margin-right: 16px;

  @media screen and (max-width: 831px) {
    width: 100%;
  }
`

const StyledReactDatePicker = styled(ReactDatePicker)`
  && {
    margin-right: 16px;
    background: #fafafa;
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 14px;
  }
`;

const Picker = styled.div`
  width: 250px;

  @media screen and (max-width: 831px) {
    width: 100%;
  }
`

const Search  = ({toggleMap, checkinDetails, handleHotelChange}) => {
  const [open, setOpen] = React.useState(false);
  const [duration, setDuration] = React.useState('3')
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0;

  const [date, setDate] = React.useState(checkinDetails.datePickerDefaultValue);
  const calculateMinTime = (date) => {
    let isToday = moment(date).isSame(moment(), "day");
    if (isToday) {
      let currentTime = moment(new Date()).toDate();
      return currentTime;
    }
    return moment().startOf("day").toDate(); // set to 12:00 am today
  };

  const [minTime, setMinTime] = React.useState(calculateMinTime(new Date()));

  const handleDateChange = (date) => {
    setDate(date);
    setMinTime(calculateMinTime(date));
  };

  const isTabletOrMobile = useMediaQuery({ maxWidth: 830 });

  const preloadedValues = {
    search: checkinDetails.location
  }

  const { handleSubmit, errors, control} = useForm({
    mode: 'onSubmit',
    defaultValues: preloadedValues
  });

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const onChangeHandle = async (value) => {
    let allSearchList = [];

    await axios.get(`https://quikstays-ng-prd.azurewebsites.net/api/v1/search?searchTerm=${value}`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if (res.data.hotels?.length > 0) {
          for (let j = 0; j < res.data.hotels.length; j++) {
            allSearchList.push({
              _id: res.data.hotels[j]._id,
              type: 'Hotels',
              name: res.data.hotels[j].name
            });
          }
        }

        if (res.data.locations?.length > 0) {
          for (let i = 0; i < res.data.locations.length; i++) {
            allSearchList.push({
              _id: i,
              type: 'Locations',
              name: res.data.locations[i]
            });
          }
        }
        setOptions(allSearchList)
      })
      .catch(error => {
        console.log(error)
      })
  }


  const onSubmit  = (data) => {
    handleHotelChange(data, date, duration)
  }

  return (
    <>
      <SearchContainer justifyContent='space-between' alignItems='baseline'>

        <StyledForm onSubmit={handleSubmit(onSubmit)} flexDirection={['column','column','column','row']}>
          <AutoCompleteContainer>
            <Controller
              control={control}
              name="search"
              id="search"
              rules={{ required:  "This is required message" }}
              render={(props) => (
                <AutoComplete
                  value={props.value}
                  height={40}
                  open={open}
                  onChange={(e, v) => {
                    props.onChange(v)
                  }}
                  error={!!errors.search}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  onChangeHandle={onChangeHandle}
                  loading={loading}
                  options={options.sort((a, b) =>  a.type === b.type ? 0 : a.type > b.type ? 1 : -1)}
                />
              )}
            />
          </AutoCompleteContainer>


          <Picker>
            <StyledReactDatePicker
              withPortal={isTabletOrMobile}
              customInput={<DatePickerInput />}
              onChange={handleDateChange}
              selected={date}
              minDate={new Date()}
              minTime={minTime}
              maxTime={moment
                .min(moment().endOf("day"), moment(minTime).add(6, "h"))
                .toDate()} // set to 23:59 pm today
              timeIntervals={30}
              showTimeSelect
              placeholderText="Select check-in date and time"
              dateFormat="MMMM dd yyyy, h:mm a"
            />
          </Picker>

          <StyledRadioGroup size="medium" defaultValue={checkinDetails.duration.toString()} buttonStyle="solid" onChange={(e) => setDuration(e.target.value)}>
            <StyledRadio value="3">3 hrs</StyledRadio>
            <StyledRadio  value="6">6 hrs</StyledRadio>
            <StyledRadio  value="9">9 hrs</StyledRadio>
            <StyledRadio  value="12">12 hrs</StyledRadio>
          </StyledRadioGroup>
          <SearchButton type="submit">Search</SearchButton>
        </StyledForm>
        <Desktop>
          <Flex>
            <Text>Hide map</Text>
            <Switch onChange={toggleMap} />
          </Flex>
        </Desktop>
      </SearchContainer>
    </>
  )
}

export default Search
