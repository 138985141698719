import lagos from '../assets/lagos.svg';
import abuja from '../assets/abuja.svg';
import calabar from '../assets/calabar.svg';
import portharcourt from '../assets/portharcourt.svg';
import ibadan from '../assets/ibadan.svg';

export const NearbyHotelsData = [
  { image: lagos, location: 'Lagos', number: 'Coming soon'},
  { image: abuja, location: 'Abuja', number: 'Coming soon'},
  { image: calabar, location: 'Calabar',  number: 'Coming soon'},
  { image: portharcourt, location: 'Port Hacourt', number: 'Coming soon'},
  { image: ibadan, location: 'Ibadan', number: 'Coming soon'}
]
