import React from "react";
import traveller from "../../assets/man.png";
import styled from "styled-components";
import { Image, Flex, Button, Box } from "rebass";
import { space } from "../styles/theme";
import { withRouter } from "react-router-dom";
import { Divider } from "../ui/Divider";


const FindRoom = styled(Button)`
  width: 150px;
  height: 42px;
  border-radius: 4px;
  color: #2657D6;
  background: #F8AD43;
  border-radius: 4px;
  &&{
    margin-top: 7px;
  }
`;

const Headline = styled.strong`
  && {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #181818;
  }
`

const StyledParagraph = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #181818;
`

const StyledSubParagraph = styled.p`
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #434F59;
  width: 75%;
`

const Container = styled(Flex)`
  background: #F7FAFD;
  padding: 0px 100px;

  @media screen and (max-width: 831px) {
    padding-left: 21px;
    padding-right: 21px;
  }
`


const Nav = () => {
  return (
    <>
      <Container pt={space[8]} flexDirection='column'>
        <Flex flexDirection={['column', 'column', 'row', 'row']}>
          <Flex flexDirection={'column'} justifyContent={'center'} mb={['21px','21px']}>
            <Headline>Quikstays is perfect for</Headline>
            <Box mt={'7px'}>
              <StyledParagraph>
                When you are stuck in traffic • Need to freshen up • Romantic getaway
              </StyledParagraph>
              <StyledParagraph>
                Waiting for your flight • Quick Meeting • Quick quiet time and more
              </StyledParagraph>
            </Box>
            <StyledSubParagraph>
              Quikstays will help you find a room that is just what you need it for while you pay for only the duration of your use.
            </StyledSubParagraph>
            <FindRoom>Find a Room</FindRoom>
          </Flex>
          <Image src={traveller} width={'fit-content'}/>
        </Flex>
        <Divider/>
      </Container>
    </>
  );
};

export default withRouter(Nav);
