import React from "react";
import Search from "./Search";
import Listings from "./Listings";
import Map from "./Map";
import { Flex, Box } from "rebass";
import { useLocation, withRouter } from "react-router-dom";
import { Roller } from "react-awesome-spinners";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Desktop } from "../../lib/Responsive";
import styled from "styled-components";
import moment from "moment";
import { Button, Affix } from "antd";
import { useMediaQuery } from "react-responsive";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Container = styled(Flex)`
  && {
    margin-bottom: 200px;
  }
`;

const filterHidden = (hotels) => {
  return hotels.filter((hotel) => !hotel.hidden);
};

const HotelListings = (props) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 830 });
  const [toggleMap, setToggleMap] = React.useState(true);
  // const datePickerDefaultValue = props.location?.datePickerDefaultValue;
  const [isLoading, setLoading] = React.useState(false);
  const [hotels, setHotels] = React.useState([]);
  const [mobileMap, setMobileMap] = React.useState(false);
  const history = useHistory();

  const query = useQuery();

  const checkin = query.get("checkin");
  const date = query.get("date");
  const duration = query.get("duration");
  const searchTerm = query.get("searchTerm");

  const checkinDetails = { checkin, date, duration, ...props.location?.state };

  React.useEffect(() => {
    setLoading(true);
    axios
      .get("https://quikstays-ng-prd.azurewebsites.net/api/v1/hotels/", {
        params: {
          checkin: checkin,
          date: date,
          duration: duration,
          searchTerm: searchTerm,
        },
      })
      .then((response) => {
        setHotels(filterHidden(response.data.data));
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [checkin, date, duration, searchTerm]);

  const handleHotelChange = (searchData, date, dur) => {
    const search = searchData.search;
      const day = moment(new Date(date)).format("YYYY-MM-DD");
      const time = moment(new Date(date)).format("HH:mm");
      const duration = dur;
      let hotelId = searchData.search._id;
    if (searchData.search.type === "Hotels") {
      history.push({
        pathname: `/hotels/${hotelId}`,
        search: `?checkin=${time}&date=${day}&duration=${duration}&searchTerm=${search.name}`,
        state: {
          hotel: search,
          datePickerDefaultValue: date,
        },
      });
    } else {
      history.push({
        pathname: `/hotels`,
        search: `?checkin=${time}&date=${day}&duration=${duration}&searchTerm=${search.name}`,
        state: {
          datePickerDefaultValue: date,
          location: search,
        },
      });
    }
  };

  const closeMap = () => {
    setMobileMap(false);
  };

  return (
    <>
      {isTabletOrMobile && !mobileMap && (
        <Affix style={{ position: "fixed", bottom: 10, left: 21 }}>
          <Button shape="round" onClick={() => setMobileMap(true)}>
            Map
          </Button>
        </Affix>
      )}

      {!mobileMap ? (
        <>
          <Search
            toggleMap={() => setToggleMap(!toggleMap)}
            checkinDetails={checkinDetails}
            handleHotelChange={handleHotelChange}
          />
          <Container>
            {isLoading ? (
              <Box margin={"auto"}>
                <Roller />
              </Box>
            ) : (
              <Listings
                hotels={hotels}
                checkinDetails={checkinDetails}
                toggleMap={toggleMap}
              />
            )}
            <Desktop>
              {toggleMap && (
                <Map
                  style={{ height: "70vh", width: "33%", marginTop: "60px" }}
                  hotels={hotels}
                  duration={duration}
                  location={searchTerm}
                />
              )}
            </Desktop>
          </Container>
        </>
      ) : (
        <Map
          style={{ height: "100vh", width: "100%" }}
          hotels={hotels}
          closeMap={closeMap}
          duration={duration}
          location={searchTerm}
        />
      )}
    </>
  );
};

export default withRouter(HotelListings);
