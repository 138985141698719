import React from "react";
import { Box, Flex } from "rebass";
import { Button } from "antd";
import styled from "styled-components";
import { Input } from "antd";
import { useForm, Controller } from "react-hook-form";
import PlacesAutocomplete from "react-places-autocomplete";
import axios from "axios";
import faqsData from "./data";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const StyledAccordion = styled(Accordion)`
  &&& {
    background: white;
  }
`;

const StyledBody = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  color: #092774;
`;

const StyledBodyText = styled.p`
  white-space: pre-wrap;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  color: black;
`;

const Icon = styled((props) => (
  <div {...props}>
    <div className="remove">
      <ExpandLessIcon htmlColor="#092774" />
    </div>
    <div className="add">
      <ExpandMoreIcon htmlColor="#092774" />
    </div>
  </div>
))`
  & > .add {
    display: block;
  }
  & > .remove {
    display: none;
  }
  .Mui-expanded & > .remove {
    display: block;
  }
  .Mui-expanded & > .add {
    display: none;
  }
`;

const StyledInput = styled(Input)`
  &&& {
    width: 100%;
    height: 52px;
    background: #fafafa;
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 44px;
  }
`;

const Submit = styled(Button)`
  width: 250px;
  height: 50px;
  background: #f1b633;
  border-radius: 5px;
`;

const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  /* identical to box height */
  color: #181818;
`;

const Container = styled(Box)`
  padding: 60px;

  @media screen and (max-width: 831px) {
    width: 100%;
    padding: 21px;
  }
`;

const GetMore = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  color: #434f59;
`;

const SectionTitle = styled.p`
  margin-bottom: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  color: #181818;
`;

const AddHotel = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [address, setAddress] = React.useState("");

  const { errors, handleSubmit, control } = useForm({
    mode: "onSubmit",
  });

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSelect = (address) => {
    setAddress(address);
  };

  const handleChangeAddress = (address) => {
    setAddress(address);
  };

  const submitAddress = (data) => {
    if (!address) {
      return;
    }
    setIsLoading(true);
    axios
      .post("https://quikstays-ng-prd.azurewebsites.net/api/v1/add-enquiries", {
        hotel: {
          name: data.hotel,
          email: data.email,
          address: address,
          phoneNumber: data.phone,
          website: data.website,
        },
        contactPerson: {
          name: data.name,
          email: data.email,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          setIsLoading(false);
          history.push("/add-hotel-success");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  return (
    <Container>
      <Title>Add your Hotels to Quikstays</Title>
      <GetMore>Get more bookings and earn more</GetMore>
      <Flex flexWrap="wrap" mt={"28px"}>
        <Box width={[1, 1, 1 / 2]} pr={[0, 0, "150px"]}>
          {/* <Flex width={1 / 2} flexDirection={"column"} mr={"154px"}> */}
          <SectionTitle>Hotel Information</SectionTitle>
          <form onSubmit={handleSubmit(submitAddress)}>
            <Controller
              control={control}
              defaultValue=""
              name="hotel"
              rules={{ required: "This is required message" }}
              render={(props) => (
                <StyledInput
                  placeholder="Hotel name"
                  onChange={(e) => props.onChange(e)}
                />
              )}
            />

            <Controller
              control={control}
              name="email"
              defaultValue=""
              rules={{
                required: "Enter a valid Email address",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Enter a valid Email address",
                },
              }}
              render={(props) => (
                <StyledInput
                  placeholder="Hotel email address"
                  onChange={(e) => props.onChange(e)}
                />
              )}
            />

            <Flex flexDirection={["column", "row", "row"]}>
              <Controller
                control={control}
                name={"phone"}
                defaultValue=""
                rules={{ required: "This is required message" }}
                render={({ onChange }) => {
                  return (
                    <PhoneInput
                      inputStyle={{ width: "100%", height: "52px" }}
                      containerStyle={{
                        width: "100%",
                        height: "52px",
                        marginRight: "7px",
                        marginBottom: "44px",
                      }}
                      country={"ng"}
                      preferredCountries={["ng", "us", "gb"]}
                      onChange={(v) => onChange(v)}
                      inputProps={{ name: "phone" }}
                    />
                  );
                }}
              />

              <Controller
                control={control}
                name="website"
                defaultValue=""
                rules={{ required: "This is required message" }}
                render={(props) => (
                  <StyledInput
                    placeholder="Website url"
                    onChange={(e) => props.onChange(e)}
                  />
                )}
              />
            </Flex>

            <PlacesAutocomplete
              value={address}
              onChange={handleChangeAddress}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <StyledInput
                    placeholder="Hotel Address"
                    {...getInputProps()}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion, index) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          key={index}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>

            <SectionTitle>Contact Person Information</SectionTitle>
            <Controller
              control={control}
              name="name"
              defaultValue=""
              rules={{ required: "This is required message" }}
              render={(props) => (
                <StyledInput
                  placeholder="Full name"
                  onChange={(e) => props.onChange(e)}
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              defaultValue=""
              rules={{ required: "This is required message" }}
              render={(props) => (
                <StyledInput
                  placeholder="Email address"
                  onChange={(e) => props.onChange(e)}
                />
              )}
            />
            <Submit htmlType="submit" loading={isLoading}>
              Submit
            </Submit>
            {!!Object.entries(errors).length && (
              <Box mt={"14px"} color="red">
                Kindly choose fill all the appropriate text fields
              </Box>
            )}
          </form>
        </Box>
        <Box width={[1, 1, 1 / 2]} pt={["28px", "28px", 0]}>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
          {faqsData.map((faq, index) => {
            return (
              <StyledAccordion
                expanded={expanded === index}
                onChange={handleChange(index)}
                key={index}
              >
                <AccordionSummary
                  expandIcon={<Icon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <StyledBody>{faq.q}</StyledBody>
                </AccordionSummary>
                <AccordionDetails>
                  <StyledBodyText>{faq.a}</StyledBodyText>
                </AccordionDetails>
              </StyledAccordion>
            );
          })}
        </Box>
      </Flex>
    </Container>
  );
};

export default AddHotel;
