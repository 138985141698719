import React from "react";
// import axios from "axios";
import ErrorPage from "../../components/ErrorPage";

const Logout = () => {
  const [error, setError] = React.useState(false);
  // const config = {
  //   headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  // };


  React.useEffect(() => {
    // axios
    //   .post(`https://quikstays-ng-prd.azurewebsites.net/api/v1/logout`, {...config})
    //   .then((response) => {
    //     console.log(response, 'herebbbbbb')
    //     localStorage.removeItem("token");
    //     window.location = "/";
    //   })
    //   .catch((error) => {
    //     setError(true);
    //   });
    try {
      localStorage.removeItem("quikstays-token");
      window.location = "/";
    } catch {
      setError(true);
    }

  }, []);

  return <>{error ? <ErrorPage /> : null}</>;
};

export default Logout;
