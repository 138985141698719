import React, {useState} from "react";
import { Flex, Box, Button } from "rebass";
import styled from "styled-components";
import { Form } from "antd";
import moment from "moment";
import { InputNumber } from "antd";
import { Radio } from "antd";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Desktop, TabletOrMobile } from "../../lib/Responsive";
import ReactDatePicker from "react-datepicker";
import { useMediaQuery } from "react-responsive";
import DatePickerInput from "../LandingPage/DatePickerInput";


const BookButton = styled(Button)`
  cursor: pointer;
  height: 38px;
  background: #092774;
  border-radius: 4px;

  && {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }
  @media screen and (max-width: 831px) {
    margin-top: 7px;
    width: 100%;
  }
`;

const StyledReactDatePicker = styled(ReactDatePicker)`
  && {
    margin-right: 16px;
    background: #fafafa;
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 14px;
  }
`;

const Picker = styled.div`
  width: 220px;
  margin-right: 16px;

  @media screen and (max-width: 831px) {
    width: 100%;
  }
`;

const MobilePicker = styled.div`
  width: 200px;
`;

const Container = styled(Flex)`
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px #f8f8f8;
  border-radius: 6px;
  padding: 10px;
  && {
    margin-bottom: 25px;
  }
`;

const MobileContainer = styled.form`
  display: flex;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px #f8f8f8;
  border-radius: 6px;
  padding: 12px;
  flex-direction: column;
  margin-bottom: 24px;
`;

const ImageContainer = styled.img`
  max-width: 210px;
  width: 100%;
  height: 100%;
  margin-right: 14px;
`;

const VerticalDivider = styled.div`
  border-left: 1px solid #d7d7d7;
  width: 1px; /* Width instead of height */
`;

export const Divider = styled.div`
  width: 100%;
  margin-top: 14px;
  margin-bottom: 14px;
  border: 1px solid #ebe6df;
`;

const StyledRadioGroup = styled(Radio.Group)``;

const StyledRadio = styled(Radio.Button)``;

const StyledInputNumber = styled(InputNumber)`
  && {
    height: 40px;
    background: #fafafa;
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 16px;

    @media screen and (max-width: 831px) {
      width: 200px;
      margin-right: 0px;
    }
  }
`;

const Title = styled.p`
  margin-bottom: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #181818;
`;

const Price = styled(Box)`
  && {
    margin: auto;
    @media screen and (max-width: 831px) {
      margin: auto;
      margin-top: 14px;
      margin-bottom: 28px;
      width: 100%;
    }
  }
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #181818;
`;

const StyledForm = styled.form`
  display: flex;
  width: 50%;
  padding: 0px 0px 0px 14px;
`;

const durations = {
  3: "three_hrs",
  6: "six_hrs",
  9: "nine_hrs",
  12: "twelve_hrs",
};

const Room = (props) => {
  const checkDuration = (duration) => {
    if (Object.keys(props.prices).includes(durations[duration])) {
      return duration;
    }
    return null;
  };

  const checkPrice = (duration) => {
    if (Object.keys(props.prices).includes(durations[duration])) {
      const durationInString = durations[duration];
      return props.prices[durationInString];
    }
    return null;
  };

  const [duration, setDuration] = React.useState(
    checkDuration(Number(props.checkinDetails.duration))
  );

  const [price, setPrice] = React.useState(checkPrice(duration));

  const [date, setDate] = React.useState(
    props.checkinDetails.datePickerDefaultValue
  );
  const calculateMinTime = (date) => {
    let isToday = moment(date).isSame(moment(), "day");
    if (isToday) {
      let nowAddOneHour = moment(new Date()).toDate();
      return nowAddOneHour;
    }
    return moment().startOf("day").toDate(); // set to 12:00 am today
  };

  const [minTime, setMinTime] = React.useState(calculateMinTime(new Date()));

  const handleDateChange = (date) => {
    setDate(date);
    setMinTime(calculateMinTime(date));
  };

  const isTabletOrMobile = useMediaQuery({ maxWidth: 830 });

  const preloadedValues = {
    number: 1,
    duration: duration,
  };

  const { errors, handleSubmit, control } = useForm({
    defaultValues: preloadedValues,
  });
  const history = useHistory();

  const onSubmit = (data) => {
    if (!date) {
      return;
    }

    history.push({
      pathname: `/booking`,
      state: {
        image: props.hotelImage,
        hotel: props.checkinDetails.searchTerm,
        room_id: props._id,
        date: moment(date).format("YYYY-MM-DD"),
        number: data.number,
        time: moment(date).format("HH:mm"),
        duration: data.duration,
        type: props.type,
        price: price,
      },
    });
  };

  const setDurationAndPrice = (duration) => {
    setDuration(duration);
    if (duration === 3) {
      setPrice(props.prices.three_hrs);
      return;
    }

    if (duration === 6) {
      setPrice(props.prices.six_hours);
      return;
    }
    if (duration === 9) {
      setPrice(props.prices.nine_hrs);
      return;
    }
    if (duration === 12) {
      setPrice(props.prices.twelve_hrs);
      return;
    }
  };

  return (
    <>
    { console.log(props) }
   
       {(() => {
        if(props?.days_excluded.includes(props.checkinDetails.weekday) != true) {
          return(


          <Desktop>
            <Container>
              <Flex width={1 / 2} pr={"14px"}>
                <ImageContainer src={props.images[0].url} />
                         <Box>
                  <Title>{props.type}</Title>
                  <p>{`${
                    props.type
                  } has the following amenities: ${props.amenities.join(
                    ", "
                  )}.`}</p>
                  
                </Box>
                <div class="custom-tag-days">
                  {(() => {
                      if(props.checkin === "00:00" && props.checkout === "12:00"){
                        return(
                            <button type="button" class="button">Half Day(AM)</button>
                          
                        )
      
                      }else if(props.checkin === "12:00" && props.checkout === "23:30"){
                        return(
                          <button type="button" class="button">Half Day(pm)</button>
                        )

                     }else if(props.checkin === "10:00" && props.checkout === "18:00"){
                        return(
                        <button type="button" class="button">Day-use </button>
                           
                          )
           
                        
                      }else{
                        return(
                          <button type="button" class="button">All Day </button>
                          )
                      }
                })()}
                </div>
                
              </Flex>
           
              <VerticalDivider />

              <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <Flex width={5.7 / 10} flexDirection={"column"}>
                  <Box>
                    <Title>Reservation</Title>
                  </Box>
                  <Box>
                    <Flex justifyContent={"space-between"} mb={"14px"}>
                      <Box mr={"24px"}>Date & Time</Box>
                      <Picker>
                        <StyledReactDatePicker
                          withPortal={isTabletOrMobile}
                          customInput={<DatePickerInput />}
                          onChange={handleDateChange}
                          selected={date}
                          minDate={new Date()}
                          maxDate={moment(props.period_end_date).toDate()}
                          minTime={minTime}
                          maxTime={moment().endOf("day").toDate()} // set to 23:59 pm today
                          timeIntervals={30}
                          showTimeSelect
                          placeholderText="Select check-in date and time"
                          dateFormat="MMMM dd yyyy, h:mm a"
                        />
                      </Picker>
                    </Flex>
                    <Flex justifyContent={"space-between"} mb={"14px"}>
                      <Box>No. of rooms</Box>
                      <Form.Item
                        validateStatus={errors.number ? "error" : "success"}
                      >
                        <Controller
                          control={control}
                          name="number"
                          id="number"
                          rules={{
                            required: true,
                            validate: (v) => v >= 1 && v <= props.available_units,
                          }}
                          render={(props) => (
                            <StyledInputNumber
                              {...props}
                              disabled={true}
                              min={1}
                              onChange={(e) => {
                                props.onChange(e);
                              }}
                            />
                          )}
                        />
                      </Form.Item>
                    </Flex>
                  </Box>
                </Flex>
                <VerticalDivider />
                <Flex
                  width={4.3 / 10}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  pl={"14px"}
                >
                  <Box>
                    <Form.Item
                      validateStatus={errors.duration ? "error" : "success"}
                    >
                      <Controller
                        rules={{
                          required: true,
                        }}
                        control={control}
                        name="duration"
                        render={({ onChange, value }) => (
                          <StyledRadioGroup
                            buttonStyle="solid"
                            value={value}
                            onChange={(e) => {
                              onChange(e.target.value);
                              setDurationAndPrice(e.target.value);
                            }}
                          >
                            {props.prices?.three_hrs && (
                              <StyledRadio value={3}>3 hrs</StyledRadio>
                            )}
                            {props.prices?.six_hours && (
                              <StyledRadio value={6}>6 hrs</StyledRadio>
                            )}
                            {props.prices?.nine_hrs && (
                              <StyledRadio value={9}>9 hrs</StyledRadio>
                            )}
                            {props.prices?.twelve_hrs && (
                              <StyledRadio value={12}>12 hrs</StyledRadio>
                            )}
                          </StyledRadioGroup>
                        )}
                      />
                    </Form.Item>
                  </Box>

                  {price ==="" || price >0 && <Price>₦{price}</Price>}
                  {price >0 && <BookButton type="submit">Book now</BookButton>}
                </Flex>
              </StyledForm>
            </Container>
          </Desktop>

        )}
       })()}
      <TabletOrMobile>
        <MobileContainer onSubmit={handleSubmit(onSubmit)}>
          <Box ml={[0, 0, "14px"]} mb={"14px"}>
            <Title>{props.type}</Title>
            <p>{`${
              props.type
            } has the following amenities: ${props.amenities.join(", ")}.`}</p>
          </Box>
          <div class="custom-tag-days mobileteb">
                  {(() => {
                      if(props.checkin === "00:00" && props.checkout === "12:00"){
                        return(
                            <button type="button" class="button">Half Day(AM)</button>
                          
                        )
      
                      }else if(props.checkin === "12:00" && props.checkout === "23:30"){
                        return(
                          <button type="button" class="button">Half Day(pm)</button>
                        )

                     }else if(props.checkin === "10:00" && props.checkout === "18:00"){
                        return(
                        <button type="button" class="button">Day-use </button>
                           
                          )
           
                        
                      }else{
                        return(
                          <button type="button" class="button">All Day </button>
                          )
                      }
                })()}
                </div>
          <Divider />
          <Flex justifyContent={"space-between"} mb={"14px"}>
            <Box mt={"14px"}>Date & Time</Box>
            <MobilePicker>
              <StyledReactDatePicker
                withPortal={isTabletOrMobile}
                customInput={<DatePickerInput />}
                onChange={handleDateChange}
                selected={date}
                minDate={new Date()}
                maxDate={moment(props.period_end_date).toDate()}
                minTime={minTime}
                maxTime={moment().endOf("day").toDate()}
                timeIntervals={30}
                showTimeSelect
                placeholderText="Select check-in date and time"
                dateFormat="MMMM dd yyyy, h:mm a"
              />
            </MobilePicker>
          </Flex>
          <Flex mb={"14px"} justifyContent={"space-between"}>
            <Box>No. of rooms</Box>
            <Form.Item validateStatus={errors.number ? "error" : "success"}>
              <Controller
                control={control}
                name="number"
                id="number"
                rules={{
                  required: true,
                  validate: (v) => v >= 1 && v <= props.available_units,
                }}
                render={(props) => (
                  <StyledInputNumber
                    {...props}
                    disabled={true}
                    min={1}
                    onChange={(e) => {
                      props.onChange(e);
                    }}
                  />
                )}
              />
            </Form.Item>
          </Flex>
          <Flex flexDirection={"column"}>
            <Form.Item validateStatus={errors.duration ? "error" : "success"}>
              <Controller
                rules={{
                  required: true,
                }}
                control={control}
                name="duration"
                render={({ onChange, value }) => (
                  <StyledRadioGroup
                    buttonStyle="solid"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                      setDurationAndPrice(e.target.value);
                    }}
                  >
                    {props.prices?.three_hrs && (
                      <StyledRadio value={3}>3 hrs</StyledRadio>
                    )}
                    {props.prices?.six_hours && (
                      <StyledRadio value={6}>6 hrs</StyledRadio>
                    )}
                    {props.prices?.nine_hrs && (
                      <StyledRadio value={9}>9 hrs</StyledRadio>
                    )}
                    {props.prices?.twelve_hrs && (
                      <StyledRadio value={12}>12 hrs</StyledRadio>
                    )}
                  </StyledRadioGroup>
                )}
              />

            </Form.Item>
            {price && <Price>₦{price}</Price>}
          </Flex>
          <BookButton type="submit">Book now</BookButton>
        </MobileContainer>
      </TabletOrMobile>
    </>
  );
};

export default Room;
