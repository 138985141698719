import React from "react";
import styled from "styled-components";
import { Flex, Button, Box, Text } from "rebass";
import { withRouter, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import Typist from "react-text-typist";
import ReactDatePicker from "react-datepicker";
import "./picker.css";
import { useMediaQuery } from "react-responsive";
import DatePickerInput from "./DatePickerInput";


import {
  AsyncTypeahead,
  Menu,
  MenuItem,
  Highlighter,
} from "react-bootstrap-typeahead";
import axios from "axios";
import "antd/dist/antd.css";
import { space } from "../styles/theme";
import plus from "../../assets/plus.svg";
import minus from "../../assets/minus.svg";
import sandClock from "../../assets/sand-clock-small.svg";
import { Image } from "rebass";
import moment from "moment";
import { groupBy, map } from "lodash";

const SearchButton = styled(Button)`
  cursor: pointer;
  width: 100%;
  height: 52px;
  background: #f8ad43;
  border-radius: 4px;

  && {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #181818;
  }
`;

const StyledReactDatePicker = styled(ReactDatePicker)`
  && {
    background: #fafafa;
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    border-radius: 5px;
    height: 52px;
    padding: 14px;
    width: 100%;
  }
`;

const SearchContainer = styled(Flex)`
  justify-content: center;
  flex-direction: column;
  padding-left: 100px;
  width: 60%;

  @media screen and (max-width: 1024px) {
    padding-left: 50px;
  }

  @media screen and (max-width: 831px) {
    width: 100%;
    padding: 21px;
  }
`;

const Headline = styled(Text)`
  && {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    color: #181818;

    @media screen and (max-width: 1024px) {
      font-size: 40px;
    }

    @media screen and (max-width: 831px) {
      font-size: 35px;
    }

    @media screen and (max-width: 640px) {
      line-height: 29px;
      font-size: 25px;
    }
  }
`;

const SubHeadline = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: #434f59;
  margin-top: ${space[3]}px;
`;

const InputContainer = styled(Flex)`
  flex-direction: column;
`;

const TimeInput = styled(Flex)`
  height: 52px;
  background: #fafafa;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  padding: 16px;

  .ant-calendar-input-wrap {
    display: none;
  }
`;

const StyledAsyncTypeahead = styled(AsyncTypeahead)`
  && {
    .rbt-input {
      background: #fafafa;
      border: 1px solid #d7d7d7;
      height: 52px;
    }
    margin-bottom: 14px;
  }
`;

const Search = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 830 });
  const [options, setOptions] = React.useState([]);
  const [duration, setDuration] = React.useState(3);
  const [isLoading, setIsLoading] = React.useState(false);
  const [date, setDate] = React.useState("");
  const [timeError, setTimeError] = React.useState(false);
   const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const calculateMinTime = (date) => {
    let moment1 = moment.utc(date);
    let isToday = moment1.isSame(moment(), "day");
    if (isToday) {
      let currentTime = moment.utc(new Date()).toDate();
      console.log(currentTime);
      return currentTime;
    }
    return moment().startOf("day").toDate(); // set to 12:00 am today
  };

  const [minTime, setMinTime] = React.useState(calculateMinTime(new Date()));
  

  
  const { errors, handleSubmit, control } = useForm({
    mode: "onSubmit",
  });

  const history = useHistory();

  const increaseDuration = () => {
    if (duration === 12) {
      return;
    }
    setDuration(duration + 3);
  };

  const decreaseDuration = () => {
    if (duration === 3) {
      return;
    }
    setDuration(duration - 3);
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    if (!date) {
      setTimeError(true);
      return;
    }

    const time = moment(new Date(date)).format("HH:mm");
    const day = moment(new Date(date)).format("YYYY-MM-DD");
    const search = data.search[0];

    if (data.search[0].type === "Hotels") {
      let hotelId = data.search[0]._id;
      history.push({
        pathname: `/hotels/${hotelId}`,
        search: `?checkin=${time}&date=${day}&duration=${duration}&searchTerm=${search.name}`,
        state: {
          hotel: search,
          datePickerDefaultValue: date || null,
        },
      });
    } else {
      history.push({
        pathname: `/hotels`,
        search: `?checkin=${time}&date=${day}&duration=${duration}&searchTerm=${search.name}`,
        state: {
          datePickerDefaultValue: date || null,
          location: search,
        },
      });
    }
  };

  const onChangeHandle = async (value) => {
    setIsLoading(true);
    let allSearchList = [];

    await axios
      .get(
        `https://quikstays-ng-prd.azurewebsites.net/api/v1/search?searchTerm=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.hotels?.length > 0) {
          for (let j = 0; j < res.data.hotels.length; j++) {
            allSearchList.push({
              _id: res.data.hotels[j]._id,
              type: "Hotels",
              name: res.data.hotels[j].name,
            });
          }
        }

        if (res.data.locations?.length > 0) {
          for (let i = 0; i < res.data.locations.length; i++) {
            allSearchList.push({
              _id: i,
              type: "Locations",
              name: res.data.locations[i],
            });
          }
        }

        setOptions(allSearchList);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDateChange = (date) => {
    setDate(date);
    setMinTime(calculateMinTime(date));

  };

  return (
    <>
      <SearchContainer>
        <Headline>
          {isTabletOrMobile ? (
            <>Book Hotel rooms hourly</>
          ) : (
            <>
              Book{" "}
              <Typist
                sentences={[
                  "Hotel rooms",
                  "Beach houses",
                  "Luxury apartments",
                  "Conference rooms",
                  "Co-Working spaces",
    
                ]}
                pauseTime={3500}
                typingSpeed={120}
                showCursor={false}
                loop={true}
              />
              hourly
            </>
          )}
        </Headline>
        <SubHeadline width={7 / 10}>
          Get functional spaces at flexible hours and at affordable prices
        </SubHeadline>
        <InputContainer mt={space[3]} pr={[0, 0, space[4], space[8]]}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              defaultValue={null}
              as={StyledAsyncTypeahead}
              isLoading={isLoading}
              filterBy={() => true}
              control={control}
              labelKey="name"
              wrapperClassName="datePicker"
              name="search"
              rules={{ required: true }}
              id="search"
              clearButton
              onSearch={(search) => onChangeHandle(search)}
              options={options}
              placeholder="Search for a hotel or location"
              renderMenu={(results, menuProps, state) => {
                let idx = 0;
                const grouped = groupBy(results, (r) => r.type);
                const items = Object.keys(grouped)
                  .sort()
                  .map((type) => {
                    return [
                      !!idx && <Menu.Divider key={`${type}-divider`} />,
                      <Menu.Header key={`${type}-header`}>{type}</Menu.Header>,
                      map(grouped[type], (option) => {
                        const item = (
                          <MenuItem key={idx} option={option} position={idx}>
                            <Highlighter search={state.text}>
                              {option.name}
                            </Highlighter>
                          </MenuItem>
                        );
                        idx++;
                        return item;
                      }),
                    ];
                  });
                return <Menu {...menuProps}>{items}</Menu>;
              }}
            />

            <StyledReactDatePicker
              withPortal={isTabletOrMobile}
              customInput={<DatePickerInput />}
              onChange={handleDateChange}
              name="dater"
              wrapperClassName="datePicker"
              selected={date}
              minDate={new Date()}
              filterTime={filterPassedTime}
              timeIntervals={30}
              showTimeSelect
              placeholderText="Select check-in date and time"
              dateFormat="MMMM dd yyyy, h:mm a"
            />

            <Flex
              justifyContent={"space-between"}
              flexDirection={["column", "column", "column", "row"]}
            >
              <TimeInput
                mr={space[2]}
                mt={space[2]}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Image src={sandClock} />
                No. of hours:
                <Image src={minus} onClick={decreaseDuration} />
                {duration}
                <Image src={plus} onClick={increaseDuration} />
              </TimeInput>
              <SearchButton mt={["14px", "14px", "14px"]} type="submit">
                Search
              </SearchButton>
            </Flex>
          </form>
          {(!!Object.entries(errors).length || timeError) && (
            <Box mt={space[2]} color="red">
              Kindly select your preferred check-in time and hotel/location
            </Box>
          )}
        </InputContainer>
      </SearchContainer>
    </>
  );
};

export default withRouter(Search);