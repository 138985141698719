const data = [
  {
    q: "How can I register with Quikstays as a space owner or Hotel Partner?",
    a:
      "As a space owner or Hotel Partner you can join Quikstays by completing an enquiry form on our Landing page.  After we complete our review process, you will receive an email notification from admin@Quikstays.com with a confirmation link to set up your account on the platform ",
  },
  {
    q: "What type of customer would use Quikstays?",
    a:
      "Our customer is anyone looking for flexibility in renting a room space at affordable prices. This includes corporate teams that require a comfortable space to collaborate and hold meetings without any interruptions, travellers in layovers/stopovers who need a space for quick relaxation, commuters avoiding peak hour traffic, event organisers hosting a guest for a few hours, and Luxury Enthusiasts.",
  },
  {
    q:
      "Why should hotels and space owners use QuikStays and what benefits are in it for them?",
    a: `• Our platform grants you the freedom to set your own prices and profit margins you want us to advertise \n• No Subscription fees is needed for you to join us\n• Instant payment would be made to space owners/hotels for each booking via the secure Paystack payment solution and a receipt will be provided to the customer and Quikstays for reconciliation\n• By adding your empty rooms or spaces it gives you the opportunity to increase you REVPAR and occupancy rate`,
  },
  {
    q: "Do I need to install a software to use the QuikStays platform?",
    a: "No, you do not have to install a software to use our platform.",
  },
  {
    q: "Do I have to modify or change my current process of operations?",
    a:
      "No need to change your current process. Our model is to target the unoccupied rooms in your hotels.  If you are unable to sell your unoccupied rooms all you need to do to upload these available spaces on the Quikstays portal to generate extra revenue for your empty rooms.",
  },
  {
    q: "How can I contact Quikstays for any issues or feedback?",
    a:
      "We have a dedicated support helpline and email for hotels and space owners to use to address any issues, or for further enquiries, please send us an email at admin@Quikstays.com",
  },
  {
    q:
      "What is the user experience journey for onboarding my rooms or spaces to Quikstays platform?",
    a:
      "The onboarding process to our platform is user-friendly and seamless, we are here to walk you through the process as well.",
  },
  {
    q:
      "What are the marketing strategies that Quikstays will use to advertise its rooms and spaces to customers?",
    a:
      "• Through Social Media i.e. Instagram, Facebook and Twitter \n• Online Targeted Ads\n• Billboards\n• Word of Mouth and Referrals\n• On our platform",
  },
  {
    q:
      "What happens if a customer does not show up for their booked rooms or spaces?",
    a:
      "Unfortunately, this happens, when a customer does not show up for their booked rooms or spaces. The space or hotel owners must notify Quikstays immediately. The customer will be charged for the booking.",
  },
  {
    q: "What is in it for Quikstays?",
    a:
      "We only charge a 5% service charge which is usually on top of whatever the hotels are looking to charge",
  },
  {
    q: "When do Hotels and space owners get paid?",
    a:
      "For same day bookings, we pay the funds into your bank account instantly. For future bookings, we pay the funds into your bank account by 12 am on the day of the booking",
  },
  {
    q: " How do we get notifications for successful bookings?",
    a:
      "Once a customer’s booking is confirmed you will receive the following notifications: \n• An automated telephone call notifying you about the booking\n• An email confirming the booking",
  },
  {
    q: "How would we be notified about cancellations?",
    a:
      "For cancellations we would notify you by email and/or a phone call from our dedicated support personnel",
  },
  {
    q: "How far in advance do the customers book with Quikstays?",
    a: "The QuikStays platform accepts same day and future bookings",
  },
  {
    q: "What happens if there is a NO SHOW?",
    a:
      "Customers that do not cancel their reservations in the allotted time will have forfeited their payment for the booking.",
  },
];

export default data;
