const data = [
  {
    q: "What is Quikstays?",
    a:
      "It is a platform for providing per hour room bookings to customers at flexible prices. Our goal is to connect space owners and service providers with their customers",
  },
  {
    q: "Who are Quikstays customers?",
    a:
      "Our customer is anyone looking for flexibility in renting a room space at affordable prices. This includes corporate teams that require a comfortable space to collaborate and hold meetings without any interruptions, travellers in layovers/stopovers who need a space for quick relaxation, commuters avoiding peak hour traffic, event organisers hosting a guest for a few hours, and Luxury Enthusiasts.",
  },
  {
    q: "How can a customer make a booking on the Quikstays platform?",
    a: `Our platform is very user friendly, and it offers per hour space and hotel bookings to customers. You can book a room for same day usage, or you can book a few days ahead.`,
  },
  {
    q:
      "Do I need to create an account to book a room or space on the Quikstays platform?",
    a:
      "NO, You can book a room as a guest without creating an account, but creating an account has some added benefits like being able to modify your booking, and getting promo discounts.",
  },
  {
    q: "What are the usage hours available for booking to customers?",
    a:
      "You can book rooms on our platform for 3hours,6hours, 9hours and 12hours",
  },
  {
    q: "What type of spaces and hotels are available on Quikstays?",
    a:
      "The spaces and hotel rooms on our platform are curated to meet the various needs of our customers. Our advertised spaces and hotels vary from luxury to budget room spaces to suit each customer’s budget",
  },
  {
    q: "How is payment made for bookings?",
    a:
      "All booking payment will be made on the platform via Paystack secured payment system.",
  },
  {
    q: "How would customers be charged for booking on a per hour basis?",
    a:
      "Customers only pay for the hours they need; prices vary based on the quality of the hotel/space and the amenities provided",
  },
  {
    q: "Can you make an advance booking on Quikstays?",
    a:
      "Yes, though most of our bookings are same day bookings. You can certainly book ahead.",
  },
  {
    q: "What happens if a customer does not show up for their booking?",
    a:
      "Unfortunately, when a customer does not show up for their booked rooms or spaces. The space/hotel owner would notify Quikstays and the customer would lose the payment made for their booking.",
  },
  {
    q: "How do I know if my reservation is confirmed?",
    a:
      "Once you have filled in all your details and payment is made, your booking details and a booking reference will appear on the screen. This code is the confirmation of your reservation. In addition, you will receive all the information by text and email.\nIf you do not see the confirmation on screen, it is possible that your reservation has not been correctly confirmed.\n If you are a registered user, you can check in your account for confirmation. You can always contact us as well to verify the status of your reservation.",
  },
  {
    q:
      "I want to book for a specific hotel, but I do not see any available rooms.",
    a:
      "We continue to add new hotels to our platform regularly, it is also possible that the hotel you are looking for does not have availability on the date and/or time you selected. You can try another date and time, if still having problems, you can contact us to request availability for the hotel directly.",
  },
  {
    q: "I need to book for more than 3, 6, 9 or 12 hours",
    a:
      "Our service only offers bookings for 3, 6, 9 or 12 hours at the moment. We offer an option to extend the stay by email before you complete your stay at your booked facility. ",
  },
  {
    q: "Can I cancel my bookings?",
    a:
      "We offer cancellation ONLY for advanced bookings. Same day bookings cannot be cancelled. You can cancel your booking by logging into in your account.",
  },
  {
    q: "How long does it take to get my booking fee/reservation refund?",
    a:
      "For reservations made with credit or debit cards, refunds are usually given after 7 or 10 working days. If these deadlines have passed but you have not received the money, please contact us to check the status of the refund.",
  },
  {
    q: "What is the Service fee?",
    a:
      "The service fee is included in total amount paid at the time of your reservation; this payment allows QUIKSTAYS to cover its operating costs. If you cancel your reservation at least 24 hours before check-in for advanced bookings this amount will be refunded to you.",
  },
  {
    q:
      "I have tried to book, but there’s an error with payment. What should I do?",
    a:
      "Sometimes our payment solution rejects payments when information is entered incorrectly. If this happens, please read the error carefully, check all fields and try again. If the error persists, try using a different card. If issue persists, please contact us or your bank to solve the problem.",
  },
  {
    q: "Can I cancel my reservation past the deadline?",
    a:
      "NO, If the check-in time is in less than 24 hours, please note that we will have to charge you for the booking.",
  },
  {
    q: "What is check-in like at the hotel?",
    a:
      "Like it always is! Just tell the receptionist/ front desk that you have made a reservation through QUIKSTAYS. They’ll confirm your booking ID and name and give you a fantastic experience",
  },
  {
    q: "What hotel services are included with the booking?",
    a: "These services will be at the discretion of the hotel",
  },
];

export default data;
