import React from "react";
import { Flex, Box } from "rebass";
import styled from "styled-components";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import faqsData from "./data";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


const StyledAccordion = styled(Accordion)`
  &&& {
    background: white;
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    box-shadow: 0px 5px 9px rgba(185, 185, 185, 0.25);
    border-radius: 5px;
    margin-bottom: 14px;
  }
`

const useStyles = makeStyles((theme) => ({
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      height: 0,
    }
  }
}));

const StyledBody = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  color: #092774;
`

const StyledBodyText = styled.p`
  white-space: pre-wrap;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  color: #434F59;
`

const Icon = styled(props => (
  <div {...props}>
    <div className="remove"><ExpandLessIcon htmlColor="#092774"/></div>
    <div className="add"><ExpandMoreIcon htmlColor="#092774"/></div>
  </div>
))`
  & > .add {
    display: block;
  }
  & > .remove {
    display: none;
  }
  .Mui-expanded & > .remove {
    display: block;
  }
  .Mui-expanded & > .add {
    display: none;
  }
`

const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  /* identical to box height */
  color: #181818;
`

const Body = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #434F59;
`

const StyledFlex = styled.p`
  background: #FAFAFA;
  border: 1px solid #D7D7D7;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 21px;
`

const Container = styled(Flex)`
  padding: 60px;

  @media screen and (max-width: 831px) {
    width: 100%;
    padding: 21px;
  }
`;

const HotelPartnerFaq = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Container flexWrap="wrap">
      <Box width={[1, 1, 1 / 3]} mb={['21px','21px',0]}>
        <Title>Help Center</Title>
        <Body>Need answers? Find them here</Body>
      </Box>
      <Box width={[1, 1, 1 / 3]}>
        {faqsData.map((faq, index) => {
          return (
            <StyledAccordion
              classes={{
                root: classes.MuiAccordionroot
              }}
              expanded={expanded === index}
              onChange={handleChange(index)}
              key={index}
            >
              <AccordionSummary
                expandIcon={<Icon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <StyledBody>{faq.q}</StyledBody>
              </AccordionSummary>
              <AccordionDetails>
                <StyledBodyText>
                  {faq.a}
                </StyledBodyText>
              </AccordionDetails>
            </StyledAccordion>
          );
        })}
      </Box>
      <Box width={[1, 1, 1 / 3]} pl={[0,0,'28px']}>
        <StyledFlex>
          <Box mb={14}>
            Hi, if you have any questions or at any point you
            need help on any matter related to Quikstay,
            please do not hesitate to reach out to us via
            phone or email.
          </Box>
          <Box>
            Email <a href = "mailto: admin@quikstays.com">admin@quikstays.com</a>
          </Box>
        </StyledFlex>
      </Box>
    </Container>
  );
};

export default HotelPartnerFaq;
