import {AnimatePresence, motion} from 'framer-motion';
import * as React from 'react';

function SlideFade({transitionOn, slideFrom = 'top', className, children, timeout = 350, onHidden}: Props) {
  let animation;

  switch (slideFrom) {
    case 'right':
      animation = {
        initial: {
          opacity: 0,
          x: '100%',
        },
        animate: {
          opacity: 1,
          x: '0%',
        },
        exit: {
          opacity: 0,
          x: '100%',
        },
      };
      break;
    case 'top':
    default:
      animation = {
        initial: {
          opacity: 0,
          maxHeight: 0,
          overflow: 'hidden',
        },
        animate: {
          opacity: 1,
          maxHeight: '1000px',
        },
        exit: {
          opacity: 0,
        },
      };
      break;
  }

  return (
    <AnimatePresence onExitComplete={() => onHidden && onHidden()}>
      {transitionOn &&
        <motion.div
          {...animation}
          transition={{
            ease: "easeIn",
            duration: timeout / 1000,
          }}
          className={className}
        >
          {children()}
        </motion.div>
      }
    </AnimatePresence>
  );
}

export default SlideFade;
