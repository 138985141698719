import React from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
import { Flex } from "rebass";
import Geocode from "react-geocode";
import { usePosition } from "use-position";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Button, Affix } from "antd";

const Location = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: blue;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    &:hover {
      z-index: 1;
    }
  }
  `;

const Map = (props) => {
  const durations = {
    3: "three_hrs",
    6: "six_hrs",
    9: "nine_hrs",
    12: "twelve_hrs",
  };

  const isTabletOrMobile = useMediaQuery({ maxWidth: 830 });
  const { latitude, longitude, error } = usePosition();
  const [location, setLocation] = React.useState({ lat: null, lng: null });
  Geocode.setApiKey("AIzaSyC19WayL9NndNjSrjVZ6D3Ils6Jt6NTeoo");

  React.useEffect(() => {
    Geocode.fromAddress(props.location).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLocation({ lat, lng });
      },
      (error) => {
        console.error(error);
      }
    );
  }, [props.location]);

  return (
    <Flex style={props.style}>
      {isTabletOrMobile && (
        <Affix
          style={{ position: "fixed", bottom: 10, left: 21, zIndex: 1000 }}
        >
          <Button shape="round" onClick={props.closeMap}>
            Back to list
          </Button>
        </Affix>
      )}
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyC19WayL9NndNjSrjVZ6D3Ils6Jt6NTeoo" }}
        defaultCenter={{ lat: 6.5243793, lng: 3.3792057 }}
        center={location}
        defaultZoom={10}
      >
        {props.hotels.map((hotel, index) => {
          return (
            <Marker
              key={index}
              labelContent={`<div class="map-price-marker"><span>200</span></div>`}
              lat={hotel.formatted_address.latitude}
              lng={hotel.formatted_address.longitude}
              name={hotel?.name}
              image={hotel.images[0].url}
              duration={props.duration}
              price={hotel.startingPrice[durations[props.duration]]}
            />
          );
        })}
        {latitude && longitude && !error && (
          <Location lat={latitude} lng={longitude} />
        )}
      </GoogleMapReact>
    </Flex>
  );
};
export default Map;
