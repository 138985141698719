import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Box } from 'rebass'

const Home = styled(Button)`
  width: 150px;
  height: 42px;
  background: #f8ad43;
  border-radius: 4px;
  && {
    margin-top: 44px;
  }
`;

const ErrorPage = () => {
  return (
    <Box py={'112px'}>
      <Result
        status="warning"
        title="Something went wrong. We've been notified about this issue and we'll take a look at it shortly."
        extra={
          <Link to="/">
            <Home type="primary">Go Home</Home>
          </Link>
        }
      />
    </Box>
  );
};

export default ErrorPage;
