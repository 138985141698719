import React from "react";
import { Image, Flex, Text } from "rebass";
import { testimonialsData } from "./data";
import "antd/dist/antd.css";
import styled from "styled-components";
import Carousel from "react-material-ui-carousel";

const BodyContainer = styled(Text)`
  && {
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    color: #434f59;
    margin-bottom: 20px;
  }
`;

const NameContainer = styled(Text)`
  && {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #434f59;
    text-align: left;
  }
`;

const CompanyContainer = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #434f59;
`;

const Container = styled(Flex)`
  text-align: center;
  flex-direction: column;
`;

const TestimonialsContainer = styled(Flex)`
  padding: 90px 435px 90px 435px;
  background: #d5e4f2;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    padding-left: 80px;
  }

  @media screen and (max-width: 831px) {
    width: 100%;
    padding: 80px;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    padding: 40px;
  }
`;

const Testimonials = () => {
  return (
    <>
      <TestimonialsContainer>
        <Carousel navButtonsAlwaysVisible={false}>
          {testimonialsData.map((testimonial, index) => {
            return (
              <Container key={index}>
                <BodyContainer>{testimonial.testimony}</BodyContainer>
                <Flex
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Image src={testimonial.image} mr={"7px"} />
                  <NameContainer mr={"7px"}>{testimonial.name}</NameContainer>
                  <CompanyContainer>{testimonial.occupation}</CompanyContainer>
                </Flex>
              </Container>
            );
          })}
        </Carousel>
        {/* </Container> */}
      </TestimonialsContainer>
    </>
  );
};

export default Testimonials;
