import React from 'react';
import { usePaystackPayment } from 'react-paystack';
import { withRouter } from 'react-router-dom';

const Paystack = (props) => {
  const config = {
    reference: (new Date()).getTime(),
    email: props.email,
    amount: props.amount*100,
    publicKey: process.env.REACT_APP_API_PAYSTACK_PUBLIC,
  };

  const onSuccess = (reference) => {
    props.handleSuccess(reference)
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    props.closeModal()
  }

  const initializePayment = usePaystackPayment(config);
  return (
    <div>
      {initializePayment(onSuccess, onClose)}
    </div>
  );
};

export default withRouter(Paystack)
