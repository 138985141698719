import React from "react";
import BookingCard from "./BookingCard";
import { Roller } from "react-awesome-spinners";
import axios from "axios";
import { Box, Flex } from "rebass";
import { Tabs } from "antd";
import styled from "styled-components";
import { Pagination } from "antd";
import "antd/dist/antd.css";

const { TabPane } = Tabs;

const Container = styled(Box)`
  padding: 60px;

  @media screen and (max-width: 831px) {
    padding: 60px 21px;
  }
`;

const pageSize = 6;

export const removeTimezone = (time) => {
  let tempTime = time.split(" ");
  tempTime =
    tempTime[0] +
    " " +
    tempTime[1] +
    " " +
    tempTime[2] +
    " " +
    tempTime[3] +
    " " +
    tempTime[4];
  return tempTime;
};

const Bookings = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [upcomingBookings, setUpcomingBookings] = React.useState([]);
  const [upcomingPageDetails, setUpcomingPageDetails] = React.useState({
    totalPage: 0,
    current: 1,
    minIndex: 0,
    maxIndex: 0,
  });
  const [pastPageDetails, setPastPageDetails] = React.useState({
    totalPage: 0,
    current: 1,
    minIndex: 0,
    maxIndex: 0,
  });
  const [pastBookings, setPastBookings] = React.useState([]);
  const [ongoingBookings, setOngoingBookings] = React.useState([]);

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("quikstays-token")}`,
    },
  };

  const handleUpcomingChange = (page) => {
    window.scrollTo(0, 0);
    setUpcomingPageDetails({
      ...upcomingPageDetails,
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
  };

  const handlePastChange = (page) => {
    window.scrollTo(0, 0);
    setPastPageDetails({
      ...pastPageDetails,
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
  };

  React.useEffect(() => {
    setIsLoading(true);
    axios
      .get(`https://quikstays-ng-prd.azurewebsites.net/api/v1/user`, config)
      .then((response) => {
        let pastBookings = [];
        let upcomingBookings = [];
        let ongoingBookings = [];
        const activeBookings = response.data.bookings.filter((booking) => {
          return !booking.cancelled;
        });

        activeBookings.forEach((booking) => {
          if (
            Date.parse(removeTimezone(booking.startTime)) < Date.now() &&
            Date.parse(removeTimezone(booking.endTime)) > Date.now()
          ) {
            ongoingBookings.push(booking);
          } else if (
            Date.parse(removeTimezone(booking.startTime)) > Date.now()
          ) {
            upcomingBookings.push(booking);
          } else {
            pastBookings.push(booking);
          }
        });

        setUpcomingBookings(upcomingBookings);
        setPastBookings(pastBookings);
        setOngoingBookings(ongoingBookings);
        setUpcomingPageDetails({
          ...upcomingPageDetails,
          totalPage: upcomingBookings.length / pageSize,
          minIndex: 0,
          maxIndex: pageSize,
        });
        setPastPageDetails({
          ...pastPageDetails,
          totalPage: pastBookings.length / pageSize,
          minIndex: 0,
          maxIndex: pageSize,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  const deleteBooking = (id) => {
    const newUpcomingBookings = upcomingBookings.filter(
      (booking) => booking._id !== id
    );
    setUpcomingBookings(newUpcomingBookings);
  };

  return (
    <Container>
      {isLoading ? (
        <Flex justifyContent={"center"} alignItems={"center"}>
          <Roller />
        </Flex>
      ) : (
        <Box>
          <Tabs defaultActiveKey="1">
            <TabPane
              tab={`Ongoing Bookings (${ongoingBookings.length})`}
              key="1"
            >
              <Flex flexWrap="wrap">
                {ongoingBookings.length === 0 ? (
                  <p>No ongoing bookings yet</p>
                ) : (
                  ongoingBookings.map((booking, index) => (
                    <Box
                      key={index}
                      width={[1, 1, 1 / 2]}
                      pr={[0, 0, "28px"]}
                      pb={"42px"}
                    >
                      <BookingCard
                        booking={booking}
                        key={index}
                        ongoing={true}
                      />
                    </Box>
                  ))
                )}
              </Flex>
            </TabPane>
            <TabPane
              tab={`Upcoming Bookings (${upcomingBookings.length})`}
              key="2"
            >
              <Flex flexWrap="wrap">
                {upcomingBookings.length === 0 ? (
                  <p>No upcoming bookings yet</p>
                ) : (
                  upcomingBookings.map(
                    (booking, index) =>
                      index >= upcomingPageDetails.minIndex &&
                      index < upcomingPageDetails.maxIndex && (
                        <Box
                          key={index}
                          width={[1, 1, 1 / 2]}
                          pr={[0, 0, "28px"]}
                          pb={"42px"}
                        >
                          <BookingCard
                            deleteBooking={(id) => deleteBooking(id)}
                            booking={booking}
                            key={index}
                          />
                        </Box>
                      )
                  )
                )}
              </Flex>
              {upcomingBookings.length !== 0 && (
                <Pagination
                  pageSize={pageSize}
                  current={upcomingPageDetails.current}
                  total={upcomingBookings.length}
                  onChange={handleUpcomingChange}
                  style={{ bottom: "0px" }}
                />
              )}
            </TabPane>
            <TabPane tab={`Past Bookings (${pastBookings.length})`} key="3">
              <Flex flexWrap="wrap">
              {pastBookings.length === 0 ? (
                  <p>No past bookings yet</p>
                ) : (
                pastBookings
                  .sort((a, b) => {
                    return (
                      Date.parse(removeTimezone(b.endTime)) -
                      Date.parse(removeTimezone(a.endTime))
                    );
                  })
                  .map(
                    (booking, index) =>
                      index >= pastPageDetails.minIndex &&
                      index < pastPageDetails.maxIndex && (
                        <Box
                          key={index}
                          width={[1, 1, 1 / 2]}
                          pr={[0, 0, "28px"]}
                          pb={"42px"}
                        >
                          <BookingCard booking={booking} key={index} />
                        </Box>
                      )
                  ))}
              </Flex>
              {pastBookings.length !== 0 && (
                <Pagination
                  pageSize={pageSize}
                  current={pastPageDetails.current}
                  total={pastBookings.length}
                  onChange={handlePastChange}
                  style={{ bottom: "0px" }}
                />
              )}
            </TabPane>
          </Tabs>
        </Box>
      )}
    </Container>
  );
};

export default Bookings;
