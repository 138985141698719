import React from "react";
import Typography from "@material-ui/core/Typography";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import HouseIcon from "@material-ui/icons/House";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import WifiIcon from "@material-ui/icons/Wifi";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import LocalLaundryServiceIcon from "@material-ui/icons/LocalLaundryService";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import SpaIcon from "@material-ui/icons/Spa";
import ScreenLockPortraitIcon from "@material-ui/icons/ScreenLockPortrait";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import StorefrontIcon from "@material-ui/icons/Storefront";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import MoneyIcon from "@material-ui/icons/Money";
import HotTubIcon from "@material-ui/icons/HotTub";
import SmokeFreeIcon from "@material-ui/icons/SmokeFree";
import CommuteIcon from "@material-ui/icons/Commute";
import GroupIcon from "@material-ui/icons/Group";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Flex, Box } from "rebass";
import PoolIcon from "@material-ui/icons/Pool";
import SmokingRoomsIcon from "@material-ui/icons/SmokingRooms";
import TvIcon from "@material-ui/icons/Tv";

const Facilities = ({ amenities }) => {
  return (
    <div>
      {amenities && amenities.length === 0 && (
        <div>Sorry, no facility found</div>
      )}

      <Flex flexWrap="wrap" width={[1, 2 / 3]}>
        {!!amenities.find((amenity) => amenity === "Air conditioning") && (
          <Box width={[1, 1 / 3]} p={3}>
            <Flex>
              <AcUnitIcon style={{ color: "#110F42" }} />
              <Typography variant="body1" className="text-capitalize ml-2">
                Air conditioning
              </Typography>
            </Flex>
          </Box>
        )}
        {!!amenities.find((amenity) => amenity === "Free Wifi") && (
          <Box width={[1, 1 / 3]} p={3}>
            <Flex>
              <WifiIcon style={{ color: "#110F42" }} />
              <Typography variant="body1" className="text-capitalize ml-2">
                Free Wifi
              </Typography>
            </Flex>
          </Box>
        )}
        {!!amenities.find((amenity) => amenity === "Housekeeping") && (
          <Box width={[1, 1 / 3]} p={3}>
            <Flex>
              <HouseIcon style={{ color: "#110F42" }} />
              <Typography variant="body1" className="text-capitalize ml-2">
                House Keeping
              </Typography>
            </Flex>
          </Box>
        )}
        {!!amenities.find((amenity) => amenity === "Swimming Pool") && (
          <Box width={[1, 1 / 3]} p={3}>
            <Flex>
              <PoolIcon style={{ color: "#110F42" }} />
              <Typography variant="body1" className="text-capitalize ml-2">
                Swimming Pool
              </Typography>
            </Flex>
          </Box>
        )}
        {!!amenities.find((amenity) => amenity === "Lounges/bars") && (
          <Box width={[1, 1 / 3]} p={3}>
            <Flex>
              <LocalBarIcon style={{ color: "#110F42" }} />
              <Typography variant="body1" className="text-capitalize ml-2">
                Lounges/bars
              </Typography>
            </Flex>
          </Box>
        )}
        {!!amenities.find((amenity) => amenity === "Onsite Laundry") && (
          <Box width={[1, 1 / 3]} p={3}>
            <Flex>
              <LocalLaundryServiceIcon style={{ color: "#110F42" }} />
              <Typography variant="body1" className="text-capitalize ml-2">
                Onsite Laundry
              </Typography>
            </Flex>
          </Box>
        )}
        {!!amenities.find((amenity) => amenity === "Fitness Center") && (
          <Box width={[1, 1 / 3]} p={3}>
            <Flex>
              <LocalLaundryServiceIcon style={{ color: "#110F42" }} />
              <Typography variant="body1" className="text-capitalize ml-2">
                Fitness Center
              </Typography>
            </Flex>
          </Box>
        )}
        {!!amenities.find((amenity) => amenity === "Spa & Wellness Center") && (
          <Box width={[1, 1 / 3]} p={3}>
            <Flex>
              <SpaIcon style={{ color: "#110F42" }} />
              <Typography variant="body1" className="text-capitalize ml-2">
                Spa & Wellness Center
              </Typography>
            </Flex>
          </Box>
        )}

        {!!amenities.find((amenity) => amenity === "On-site parking") && (
          <Box width={[1, 1 / 3]} p={3}>
            <Flex>
              <LocalParkingIcon style={{ color: "#110F42" }} />
              <Typography variant="body1" className="text-capitalize ml-2">
                On-site parking
              </Typography>
            </Flex>
          </Box>
        )}
        {!!amenities.find((amenity) => amenity === "Smoking Room") && (
          <Box width={[1, 1 / 3]} p={3}>
            <Flex>
              <SmokingRoomsIcon style={{ color: "#110F42" }} />
              <Typography variant="body1" className="text-capitalize ml-2">
                Smoking Room
              </Typography>
            </Flex>
          </Box>
        )}
        {!!amenities.find((amenity) => amenity === "Television") && (
          <Box width={[1, 1 / 3]} p={3}>
            <Flex>
              <TvIcon style={{ color: "#110F42" }} />
              <Typography variant="body1" className="text-capitalize ml-2">
                Television
              </Typography>
            </Flex>
          </Box>
        )}
        {!!amenities.find((amenity) => amenity === "Housekeeping") && (
          <Box width={[1, 1 / 3]} p={3}>
            <Flex>
              <TvIcon style={{ color: "#110F42" }} />
              <Typography variant="body1" className="text-capitalize ml-2">
                Housekeeping
              </Typography>
            </Flex>
          </Box>
        )}
        {!!amenities.find(
          (amenity) => amenity === "Non-smoking rooms (generic)"
        ) && (
          <Box width={[1, 1 / 3]} p={3}>
            <Flex>
              <SmokeFreeIcon style={{ color: "#110F42" }} />
              <Typography variant="body1" className="text-capitalize ml-2">
                Non-smoking rooms
              </Typography>
            </Flex>
          </Box>
        )}
        {!!amenities.find((amenity) => amenity === "Street side parking") && (
          <Box width={[1, 1 / 3]} p={3}>
            <Flex>
              <CommuteIcon style={{ color: "#110F42" }} />
              <Typography variant="body1" className="text-capitalize ml-2">
                Street side parking
              </Typography>
            </Flex>
          </Box>
        )}
      </Flex>
    </div>
  );
};

export default Facilities;
