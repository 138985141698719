import React from "react";
import { Flex, Box, Image } from "rebass";
import styled from "styled-components";
import { Input, Button, Form } from "antd";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import quickStays from "../../assets/quikstays.svg";
import { useHistory } from "react-router-dom";

const StyledInput = styled(Input)`
  &&& {
    height: 52px;
    background: #fafafa;
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 7px;

    @media screen and (max-width: 831px) {
      width: 100%;
    }
  }
`;

const StyledImage = styled(Image)`
  width: 150px;
`;

const Submit = styled(Button)`
  width: 250px;
  height: 50px;
  background: #f1b633;
  margin-bottom: 21px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #181818;
`;

const Title = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 14px;
`;

const PasswordLink = styled.p`
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

const Container = styled(Flex)`
  && {
    padding: 140px 0;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 831px) {
      padding: 21px;
      margin-bottom: 50px;
    }
  }
`;

const StyledForm = styled.form`
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100%;
`;

const RecoverAccount = () => {
  let history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const { errors, handleSubmit, control } = useForm({
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post("https://quikstays-ng-prd.azurewebsites.net/api/v1/reset-password", {
        email: data.email,
      })
      .then(function (response) {
        if (response.status === 200) {
          history.push("/reset-password-success");
        }
      })
      .catch((error) => {
        setError('An error occurred, please try again later');
        setLoading(false);
      });
  };

  return (
    <Container>
      <StyledImage src={quickStays} />
      <Title>Forgot Password</Title>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Box width={[1, 1, 3 / 10]}>
          <Form.Item
            style={{ width: "100%" }}
            validateStatus={errors.firstname ? "error" : "success"}
          >
            <Controller
              control={control}
              name="email"
              rules={{ required: "This is required message" }}
              render={(props) => (
                <StyledInput
                  {...props}
                  placeholder="Email"
                  onChange={(e) => {
                    props.onChange(e);
                  }}
                />
              )}
            />
          </Form.Item>
        </Box>
        <Flex justifyContent={"center"} flexDirection={"column"}>
          <Submit htmlType="submit" loading={loading}>
            <p style={{ fontSize: 14 }}>Send Password Reset Link</p>
          </Submit>
        </Flex>
        {!!Object.entries(errors).length && (
          <Box mt={"7px"} color="red">
            Kindly fill the appropriate text fields
          </Box>
        )}
        {error && (
          <Box mt={"7px"} color="red">
            {error}
          </Box>
        )}
      </StyledForm>
    </Container>
  );
};

export default RecoverAccount;
