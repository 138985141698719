import verified from '../assets/verified.svg';
import sandClock from '../assets/sand-clock.svg';
import chat from '../assets/chat.svg';

export const HowItWorksData =
[
  {
    title: 'Book Per Hour',
    feature: 'Decide the check in and check out time',
    logoSrc: verified,

  },
  {
    title: 'Luxury & Comfort',
    feature: 'Enjoy all the services included within the hotel as you would on a full stay',
    logoSrc: sandClock,
  },
  {
    title: 'Quick Confirmation',
    feature: 'Immediate confirmation and free cancellation',
    logoSrc: chat,
  }
]
