import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 40px 49px;
  font-size: 12pt;
  font-family: Avenir, sans-serif;
  @media screen and (max-width: 831px) {
    padding: 21px;
  }
`;

const VendingAgreement = () => {
  return (
    <Container>
      <div
        dangerouslySetInnerHTML={{
          __html: `<p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">QUIKSTAYS ONLINE VENDING AGREEMENT</span></p>
          <p><br></p>
          <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This agreement is between the Quikstays (&ldquo;Quikstays&rdquo; or &ldquo;the Company&rdquo;) and the Vendor and contains the warranties and conditions for the use of the Website in addition with other terms contained in such other documents as the Vendor may become aware.</span></p>
          <p><br></p>
          <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Whereas:</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:upper-alpha;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: 13.649999999999999pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Quikstays is a company that operates a marketplace website for booking of accommodation services by Users, which services are rendered by the Vendor.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="2" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:upper-alpha;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: 13.649999999999999pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Vendor render accommodation services to individuals and are responsible for fulfilling accommodation services contracted by User on the website pursuant to this agreement.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="3" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:upper-alpha;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: 13.649999999999999pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Vendor wishes to provides its accommodation service listings to Users through the website. The Vendor by signing up on the website, agrees to be bound by the terms of this agreement.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="2" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Definition</span></p>
              </li>
          </ol>
          <p><br></p>
          <p dir="ltr" style="line-height:1.3800000000000001;margin-left: 36pt;text-align: justify;margin-top:0pt;margin-bottom:10pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">In this Agreement and unless inconsistent with</span><span style="font-size:12pt;font-family:'MS Mincho';color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"></span><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">or otherwise indicated by the context, the following words shall have the meanings specified next to them:</span></p>
          <div align="left" dir="ltr" style="margin-left:48.75pt;">
              <table style="border:none;border-collapse:collapse; display: block;
              overflow-x: auto;
              white-space: nowrap;"">
                  <colgroup>
                      <col width="60">
                      <col width="133">
                      <col width="354">
                  </colgroup>
                  <tbody>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">S/N</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Word</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Meaning</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">User</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Any individual or corporate organization that accesses this website using electronic devices such as computers, mobile phones, tablets, etc., for the purposes including but not limited to bookings and making inquiries about online accommodation bookings for short lets and other services facilitated by the marketplace provided by Quikstays. User shall not include such persons as employees of Quikstays whilst acting in course of their employment or such persons engaged as consultants or otherwise providing services to Quikstays.</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="2" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Website</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The domain name:&nbsp;</span><span style="font-size:11pt;font-family:Arial;color:#222222;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><a href="http://www.quikstays.com" style="text-decoration:none;"><span style="font-size:11pt;font-family:Arial;color:#1155cc;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">www.QuikStays.com</span></a><span style="font-size:11pt;font-family:Arial;color:#222222;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="3" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Accommodation service</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The provision of properly furnished properties for habitation by User.</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="4" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Intellectual property</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">All Content included on the Website, unless uploaded by Users, is the property of Quikstays, its affiliates or other relevant third parties. Such content includes texts, graphics, images, audio, video, software, data compilations, page layout, underlying code and software and any other form of information capable of being stored in a computer that appears on or forms part of this Website.</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="5" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Bookings</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:11pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The reservation of accommodation in advance on the website.</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="6" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Service</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Accommodation service.</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="7" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Party</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Either a User or a Vendor in relevant context.</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="8" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Vendor</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">A provider of accommodation services advertising on the website.</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="9" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Agreement</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">means the provision of this Agreement and includes any other documents (or parts thereof) that specifically relate to or may relate to this Agreement from time to time or as may be agreed to by the Parties.</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="10" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Confidential Information</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">means the provisions of this Agreement and all information in respect of the operations of each party including without prejudice to the generality of the foregoing; any ideas, business, methods finances, prices, business, financial, marketing, development or manpower plans, customers lists or details, computer systems and software, knowhow or other matters connected with the products or services manufactured, marketed, sold, provided or obtained by each party, and information concerning either party&rsquo;s relationships with actual or potential clients or customers and the needs and requirements of such persons.</span></p>
                          </td>
                      </tr>
                      <tr style="height:0pt;">
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <ol start="11" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                  <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                              </ol>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Dispute</span></p>
                          </td>
                          <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                              <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">means any dispute, difference, controversy or claim arising from this Agreement</span></p>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <p><br></p>
          <p><br></p>
          <ol start="3" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Scope of Agreement</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Quikstays operates a virtual marketplace platform which connect the Vendor (owner of accommodation and/or accommodation service provider) to User (willing clients) who requires accommodation and/or listed services by the Vendor. The Parties understand that the Website is not a general online marketplace.&nbsp;</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="4" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Legal Framework</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;pre;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The website enables the Vendor to solicits bookings for accommodation services and reach out to the User.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="2" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The booking of accommodation services through the Website is an agreement between the Vendor and the User, which is deemed concluded from the moment the booking is confirmed by the Vendor and that confirmation sent to the User. The Vendor is responsible for the delivery of the accommodation service bookings made on the website.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="3" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Vendor will be liable for the quality of the accommodation and services displayed/advertised on the website.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="4" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Quikstays as the operator of the website, acts only as a provider of the information on accommodation and service at a fee and is not a party to the accommodation service agreement. Quikstays to this extent, is therefore not liable in any way whatsoever for the performance of the agreements.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="5" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">As operator, Quikstays acts as agent for the Vendor in relation only to advertisement and/or solicitation for the accommodation service agreement between the Vendor and the User. Quikstays also acts as agent for the Vendor with regards to payment for accommodation service bookings made on the Website. In this regard, Quikstays has been authorized by the Vendor to receive payments from the User on its behalf and render the received funds to the Vendor subject to any deductions in accordance with the terms of this agreement.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="6" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Quikstays shall not disburse any funds received on the Vendor&rsquo;s behalf until the accommodation service is utilized by the User.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="7" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Quikstays shall provide necessary technical support for ease of transaction on the website between the Vendor and the User and to enable both parties monitor the progress of all transactions and bookings made on the website.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="8" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Also incorporated as binding on the parties to this agreement is the terms and conditions and the privacy policy of the Website.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="5" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Bookings</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Vendor shall commence the display of and booking of its accommodation service on the website upon the execution of this agreement, unless otherwise agreed.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="2" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">When a User makes a booking, the Vendor will ensure the confirmation of the booking(s). Upon confirmation of the booking by the Vendor, an accommodation service agreement is entered by the Vendor and the User.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="3" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Vendor shall endeavour to ensure the availability of specified accommodation service displayed on the website and where the Vendor is or likely to become unable to fulfil the booking to the User, the Vendor shall cease to accept any further booking for such accommodation service or those range of services by placing an &ldquo;Out of Stock&rdquo; notice against such accommodation service listings.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="4" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Vendor shall provide a clear description and easy-to-comprehend details of the features of its accommodation service displayed on the website.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="5" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Vendor shall ensure that the accommodation service utilized by Users complies with the description displayed on the website and shall be liable for any misrepresentation, misdescription or damage resulting therefrom.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="6" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The price of the accommodation services offered by Vendors on the website may not be more expensive than the price of the products at the Vendors&rsquo; business premises; taxes, commissions and delivery costs excluded.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="7" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Quikstays shall entertain all complaints and comments by the User regarding the booking of accommodation services contracted on the website.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="6" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Additional obligations &ndash; Use of the website</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Vendor shall not send any kind of promotional material or such other information unrelated to a booking made on the website, to the Users.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="2" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Vendor shall indemnify Quikstays against all claims and losses (including legal representation) that may arise against Quikstays due to acts or omissions of the Vendor.</span></p>
              </li>
          </ol>
          <p dir="ltr" style="line-height:1.2;margin-left: 28.35pt;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span></p>
          <ol start="3" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Vendor shall not provide any description (including image or text) which is unlawful, immoral, obnoxious, obscene, vulgar and is against public policy. Where a Vendor fails in this regard, Quikstays shall do and cause to be done all such acts as are necessary to prevent continuation of such violations and consequential business disrepute to itself. The Vendor shall at all times be responsible for compliance of all applicable laws and regulations including but not limited to sector regulations and tax laws.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="4" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Vendor shall provide full and accurate description of its accommodation service(s) to enable the User to make informed decisions. The Vendor shall not misrepresent the description of product(s) displayed on the website. The Vendor shall be solely and totally liable to the User for the accommodation displayed on the website.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="5" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Vendor shall at all times utilize good industry practices in the information technology industry with respect to comparable services to prevent the introduction of viruses onto the website.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="6" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Quikstays is entitled to change, modify or discontinue either temporarily or permanently the provision of the information or service by altering or deleting the information or service from the website at any given time.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="7" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Vendor shall gain access to the website through a Vendor&rsquo;s user account. The Vendor is entitled to have only one account on the website. If the Vendor operates its business through several sub-vendors, the Vendor shall be provided with separate sub-accounts to use the website for the use of such-sub-vendor. The account details for the Vendor (and if applicable, the sub-vendors) will be communicated to the Vendors separately.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="7" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Warranties, Representations and Undertakings</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Vendor warrants that it does not display any accommodation or service prohibited by law or which violates or infringes on any third-party&rsquo;s right and that the service is certified by relevant regulatory agencies in the country of origin of the Vendor. It also has full authority and legal capacity to enter into this Agreement and by entering into this agreement, it is not in violation of any third-party rights; its obligations under this agreement being legally enforceable in the law of the country of its residence or establishment.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="2" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Quikstays and the Vendor warrants that they are authorized business establishments, having all requisite licenses and approvals to perform their obligations in accordance with this agreement.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="3" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Quikstays is not liable for the effective functioning of the website in any device. The Vendor undertakes to install and or use all updates, upgrades or modules provided by Quikstays. Quikstays is not liable for any damages or loss of profit resulting from the failure to perform the obligation in this sub-paragraph.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="8" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Term, Termination and Suspension of Use</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This Agreement is entered into for an unspecified period of time (without term), unless agreed otherwise.&nbsp;</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="2" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Both the Vendor and Quikstays have the right to terminate this Agreement at any time by giving one month notice in advance.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="3" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">If the Vendor breaches any of its obligations under this Agreement, Quikstays has the right to suspend the Vendor from using the website without any obligation to compensate for the loss of profit. The Vendor shall have breached this Agreement if the Vendor:</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:lower-alpha;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: 13.649999999999999pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Fails to deliver to a User the service booked.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="2" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:lower-alpha;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: 13.649999999999999pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Delivers service below the advertised standard.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="3" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:lower-alpha;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: 13.649999999999999pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Misrepresentations on its displayed service on the website.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="4" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:lower-alpha;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: 13.649999999999999pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Display services without relevant regulatory approvals where they are necessary.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="5" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:lower-alpha;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: 13.649999999999999pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Engages in any acts that brings Quikstays or any of its partners or other online marketplace vendors to disrepute.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="4" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Quikstays shall have the right to extraordinarily terminate this Agreement without a notice period, if the Vendor fails to comply with or substantially breaches any of the obligations set forth in this Agreement, if the Vendor is temporarily or permanently suspended from using the website or if it has become insolvent.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="5" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The termination of this Agreement will not affect:&nbsp;</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="3" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: 10.350000000000001pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;any accrued rights of either party, including any right to receive any payments due but unpaid before the termination; or</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="2" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="3" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: 10.350000000000001pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;obligations, which are expressed to survive the termination. Termination in this context shall not affect any bookings already made by a User and confirmed by the Vendor.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="6" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Upon termination of this Agreement, all materials, devices and equipment belonging to and provided by Quikstays for effective use of the website shall be returned to Quikstays in good order unless otherwise agreed between the parties.&nbsp;</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="9" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Indemnity</span></p>
              </li>
          </ol>
          <p><br></p>
          <p dir="ltr" style="line-height:1.2;margin-left: 28.35pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Vendor shall hold indemnified Quikstays, its partners, officers, employees, representatives, agents from and against all losses, damages, claims, suits, legal proceedings and otherwise howsoever arising from or in connection with any claim including but not limited to claim for any infringement of any intellectual property rights or any other right of any third-party or of law, concerning quality, and any claim in relation to the services offered by the Vendor, the breach of any of the Vendor&rsquo;s warranty, representation or undertaking or in relation to the non-fulfillment of any of its obligations under this Agreement or arising out of the Vendor infringing any applicable law, regulation including but not limited to Intellectual Property Right and tax law, Excise and Import duties, etc.</span></p>
          <p><br></p>
          <ol start="10" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Licensing, Data Processing and Intellectual Property Right</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Quikstays hereby grants the Vendor a revocable, non-exclusive, non-transferable, non-sublicensable license to use the Website for the purpose of displaying and offering to Users its accommodation services.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="2" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">By concluding this Agreement, the Vendor grants Quikstays license to display on the website and using it in marketing all materials (including the photos of services) sent to it by the Vendor. The license is valid for the entire period of this Agreement. The Vendor procures that all material to be displayed on the website (including, but not limited to photos of its services) is free from third-party rights. The Vendor shall however be responsible for any third-party claims arising from the wrongful use of a material or photograph of the services displayed on the Website. &nbsp;In cases where the photos of the services are taken on the expense of or by a person appointed by Quikstays, all rights related thereto (including intellectual property rights and the right of use) shall belong to Quikstays. If the Vendor has opted to buy the license for the use of the photos of the services on the Website, the Vendor will be granted access to and it may use such photos for any purposes, except for displaying them on platforms that are competing with Quikstays and using them for the provision of the same services as the Vendor provides to Quikstays.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="3" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">All intellectual property rights regarding the software, documentation or information used or developed by or on behalf of Quikstays during the provision of services under this Agreement (including the website, Confidential Information and where relevant, photos of services), as well as all data and statistics about the sales made through the website belong to Quikstays. The Vendor shall not copy, modify, adapt, reverse-engineer, decompile or otherwise discover the source code of the website or any other software used by Quikstays Limited. Quikstays shall maintain the data and statistics about the sales for each reference period for a period of one year after the end of said reference period or for such period of time as may be prescribed by data regulations.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="4" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Vendor agrees that Quikstays may use its name, logo or photos in Quikstays&rsquo;s advertising materials during the entire period of this Agreement.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="11" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Independent Contractors</span></p>
              </li>
          </ol>
          <p><br></p>
          <p dir="ltr" style="line-height:1.2;margin-left: 28.35pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Parties hereby enter into this Agreement as independent contractors, and this Agreement will not be construed to create a partnership, joint venture, employment relationship or agency between them except where expressly stated. Neither Party shall represent itself to be an employee of the other or enter into any agreement or legally binding commitment or statement on the other&rsquo;s behalf or in the other&rsquo;s name.</span></p>
          <p><br></p>
          <ol start="12" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Confidentiality</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The terms of this agreement, the exchange and transfer of information and data of Users pursuant to it, are confidential to the Parties and their advisers, who shall not disclose them to, or otherwise communicate them to, any third-Party other than:</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:lower-alpha;font-size:11pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: 13.649999999999999pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">to the Parties&apos; respective auditors, insurers and lawyers on terms which preserve confidentiality;</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="2" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:lower-alpha;font-size:11pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: 13.649999999999999pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">pursuant to an order of a court of competent jurisdiction, or pursuant to any proper order or demand made by any competent authority or body where they are under a legal or regulatory obligation to make such a disclosure; and</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="3" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:lower-alpha;font-size:11pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: 13.649999999999999pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">as far as necessary to implement and enforce any of the terms of this Agreement.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="2" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">During the term of this Agreement and for a period of five (5) years following the expiry or termination of this Agreement, Quikstays and the Vendor shall not disclose and shall maintain the confidentiality of all Confidential Information of the other party.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="3" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Confidential Information of the other party may be disclosed to third parties only subject to a prior written consent of the other party. Notwithstanding the above, Confidential Information may be disclosed to the party&rsquo;s attorneys, auditors, accountants, consultants and subcontractors, provided that such persons perform their obligations in connection with this Agreement and are subject to a confidentiality clause.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="13" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Force Majeure</span></p>
              </li>
          </ol>
          <p><br></p>
          <p dir="ltr" style="line-height:1.2;margin-left: 28.35pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Parties agree that there shall not be attached any liability for any inability to carry out any obligation under this Agreement if such inability was attributable to an event of force majeure including but not limited to systems downtime, server failure, civil commotion, strikes or lock outs, war, flood, insurrection and other acts of God provided however that the party affected by force majeure event must within two (2) days provide the other party with the notice of the event and where an event of force majeure subsists for more than One (1) month, the Party affected by such force majeure event shall be deemed to have voluntarily excused itself from the transaction contemplated by this Agreement.</span></p>
          <p><br></p>
          <ol start="14" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Governing Law/Dispute Resolution</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This Agreement shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria in force from time to time and any disputes arising out of or in connection with this Agreement, shall be settled by both parties taking reasonable steps to amicably resolve any dispute or misunderstanding;</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="2" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Where the Parties are unable to resolve the dispute amicably within 30 (thirty) days from receipt of a written notification by a Party of the existence of a dispute, it shall be referred to arbitration in accordance with the provisions of the Arbitration and Conciliation Act, Cap A18, LFN, 2004.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="3" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The arbitration tribunal shall be made up of a sole arbitrator appointed by the Chairman/Chairperson of the Chartered Institute of Arbitrators UK (Nigeria Branch) on the application of either Party (notice of such application being given to the other Party) PROVIDED THAT the arbitrator so appointed shall not be a present or former employee, agent, consultant or Counsel to either Party.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="4" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The language of the arbitration shall be English. The place of arbitration shall be Lagos. The decision of the arbitrator shall be final and binding on the Parties.</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="5" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="2" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The Cost of the Arbitration shall be borne by the parties and each party shall be responsible for its own cost which said cost may be taken into consideration in the final award by the arbitral tribunal&nbsp;</span></p>
              </li>
          </ol>
          <p><br></p>
          <ol start="15" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
              <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;margin-left: -18pt;padding-left: 10.350000000000001pt;">
                  <p dir="ltr" role="presentation" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Entire Agreement</span></p>
              </li>
          </ol>
          <p><br></p>
          <p dir="ltr" style="line-height:1.2;margin-left: 28.35pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This Agreement embodies the entire agreement and understanding of the Parties and supersedes any and all other prior and contemporaneous agreements, correspondence, arrangements and understandings (whether written or oral) between the Parties with respect to its subject matter. No amendment to or modification of this Agreement shall be effective unless it is in writing and signed by each Party</span></p>
          <p><br></p>
          <p><br></p>
          <p><br></p>
          <p><br></p>`,
        }}
      />
    </Container>
  );
};

export default VendingAgreement;
