import React from "react";
import GlobalStyle from "./globalStyles";
import LandingPage from "./components/LandingPage";
import NavBar from "./components/Nav";
import HotelListings from "./components/HotelListings";
import Hotel from "./components/Hotel";
import Booking from "./components/Booking";
import ExtendBooking from "./components/Booking/ExtendBooking";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AddHotel from "./components/AddHotel";
import Signup from "./components/Onboarding/Signup";
import Login from "./components/Onboarding/Login";
import Logout from "./components/Onboarding/Logout";
import Footer from "./components/Footer";
import BookingSuccess from "./components/Booking/BookingSuccess";
import BookingFailure from "./components/Booking/BookingFailure";
import jwtDecode from "jwt-decode";
import HotelPartnerFaq from "./components/HelpCenter/HotelPartnerFaq";
import CustomerFaq from "./components/HelpCenter/CustomerFaq";
import ScrollToTop from "./lib/scrollToTop";
import Bookings from "./components/Bookings";
import AddHotelSuccess from "./components/AddHotel/AddHotelSuccess";
import RecoverAccount from "./components/Onboarding/RecoverAccount";
import PasswordReset from "./components/Onboarding/PasswordReset";
import PasswordResetSuccess from "./components/Onboarding/ResetPasswordSuccess";
import NotFound from "./components/NotFound";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import VendingAgreement from "./components/VendingAgreement"

function App() {
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    try {
      const jwt = localStorage.getItem("quikstays-token");
      const user = jwtDecode(jwt);
      setUser(user);
    } catch (ex) {}
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop>
        <GlobalStyle />
        <div
          style={{
            display: "flex",
            minHeight: "100vh",
            flexDirection: "column",
          }}
        >
          <NavBar user={user} />
          <div style={{ flex: 1, minHeight: "700px" }}>
            <Switch>
              <Route path="/" component={LandingPage} exact />
              <Route path="/hotels" component={HotelListings} exact />
              <Route path="/hotels/:id" component={Hotel} exact />
              <Route
                path="/booking"
                render={(props) => <Booking {...props} user={user} />}
              />
              <Route
                path="/extend-booking/:token"
                render={(props) => <ExtendBooking {...props} user={user} />}
              />
              <Route path="/add-hotel" component={AddHotel} exact />
              <Route path="/signup" component={Signup}></Route>
              <Route path="/login" component={Login}></Route>
              <Route path="/logout" component={Logout} exact />
              <Route path="/booking-success" component={BookingSuccess} exact />
              <Route
                path="/add-hotel-success"
                component={AddHotelSuccess}
                exact
              />
              <Route path="/failure" component={BookingFailure} exact />
              <Route path="/bookings" component={Bookings} exact />
              <Route path="/recover-account" component={RecoverAccount} exact />
              <Route
                path="/reset-password-success"
                component={PasswordResetSuccess}
                exact
              />
              <Route
                path="/reset-password/:token"
                component={PasswordReset}
                exact
              />
              <Route
                path="/hotel-partner-faqs"
                component={HotelPartnerFaq}
                exact
              />
              <Route path="/customer-faqs" component={CustomerFaq} exact />
              <Route path="/privacy-policy" component={PrivacyPolicy} exact />
              <Route path="/terms-and-conditions" component={TermsAndConditions} exact />
              <Route path="/vending-agreement" component={VendingAgreement} exact />
              <Route component={NotFound} />
            </Switch>
          </div>
          <Footer />
        </div>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
