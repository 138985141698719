import React from "react";
import styled from "styled-components";
import { Image, Flex, Button } from "rebass";
import { Link } from "react-router-dom";
import warning from '../../assets/warning.svg'


const Home = styled(Button)`
  width: 150px;
  height: 42px;
  background: #F8AD43;
  border-radius: 4px;
  && {
    margin-top: 44px;
  }
`;

const Header = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1A1C20;
`

const Body = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  width: 300px;
`

const Container = styled(Flex)`
  padding: 100px 0px 250px 0px;
  flex-direction: column;
  align-items: center;
`

const BookingFailure = () => {
  return (
    <>
      <Container flexDirection='column'>
        <Header>Booking Failure</Header>
        <Image
          src={warning}
          width={'84px'}
          mt={'44px'}
          mb={'24px'}
        />
        <Body>
          Sorry, your hotel booking was not successful.
          You can contact
          us at <a href = "mailto: support@quikstays.com">support@quikstays.com</a> for more details
        </Body>
        <Link to="/">
          <Home >
            Home
          </Home>
        </Link>
      </Container>
    </>
  );
};

export default BookingFailure;
