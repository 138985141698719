import React from "react";
import { Image, Flex } from "rebass";
import { NearbyHotelsData } from "../../data/nearbyHotels";
import styled from "styled-components";

const Headline = styled.strong`
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 46px;
  color: #181818;
  text-align: center;
  margin-bottom: 32px;
`;

const BodyContainer = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

const Number = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0c7d85;
`;

const PriceWrapper = styled(Flex)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 10px;
  color: #434f59;
  padding-left: 16px;
`;

const Container = styled(Flex)`
  background: #f7fafd;
  padding: 0px 100px;

  @media screen and (max-width: 831px) {
    width: 100%;
    padding: 21px;
  }
`;

const NearbyHotels = () => {
  return (
    <>
      <Container flexDirection={"column"} pt={"92px"} pb={"190px"}>
        <Headline>Enjoy Quikstays all over Nigeria</Headline>
        <Flex justifyContent={"space-between"} flexWrap="wrap">
          {NearbyHotelsData.map((NearbyHotel, index) => {
            return (
              <Flex flexDirection={"column"} width={[1/2, 1/2, 1 / 5]} key={index}>
                <Image src={NearbyHotel.image} />
                <Flex pl={"16px"}>
                  <BodyContainer>{NearbyHotel.location}</BodyContainer>&nbsp;
                </Flex>
                <PriceWrapper>
                  <Number>{NearbyHotel.number}</Number>&nbsp;
                </PriceWrapper>
              </Flex>
            );
          })}
        </Flex>
      </Container>
    </>
  );
};

export default NearbyHotels;
