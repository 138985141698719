import React from "react";
import styled from "styled-components";
import { Flex, Image, Box } from "rebass";
import quickStays from "../assets/quikstays-footer.svg";
import { Link } from "react-router-dom";

const Container = styled(Flex)`
  justify-content: space-between;
  background: #092774;
  padding: 105px 100px 30px 100px;
  margin-top: 200px;

  @media screen and (max-width: 831px) {
    padding: 60px 20px;
    margin-right: 100px;
  }
`;

const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #8b9fd3;
`;

const Subfooter = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #8b9fd3;
`;
const Title = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
`;

export const Divider = styled.div`
  width: 100%;
  margin-top: 72px;
  margin-bottom: 15px;
  border: 1px solid #61729d;
`;

const FooterText = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
`;

const AllRightsReserved = styled(Box)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
`;

const FooterCopy = styled(Text)`
  width: 300px;
  @media screen and (max-width: 831px) {
    align-items: center;
  }
`;

const Footer = (props) => {
  return (
    <>
      <Container flexDirection={"column"}>
        <Flex
          justifyContent={"space-between"}
          flexDirection={["column", "column", "row"]}
        >
          <Flex flexDirection={"column"} mb={"14px"}>
            <Image
              src={quickStays}
              sx={{
                width: "120px",
              }}
            />
            <FooterCopy>
              A microstay booking that will allow hotels to set their own price
              per hour and allow consumers to book hotels flexibly per hour
            </FooterCopy>
          </Flex>
          <Flex flexDirection={"column"} mb={"14px"}>
            <Title>Quikstays in</Title>

            <Flex flexDirection={"column"}>
              <Text>Lagos</Text>
              <Text>Abuja</Text>
              <Text>Port Harcourt</Text>
              <Text>All Location</Text>
            </Flex>
          </Flex>
          <Flex flexDirection={"column"}>
            <Title>Quick Links</Title>
            <Flex flexDirection={"column"}>
              <Subfooter to="/">Home</Subfooter>
              <Subfooter to="https://quickstays.azureedge.net">
                About us
              </Subfooter>
              <Subfooter to="/add-hotel">Add Hotel</Subfooter>
            </Flex>
          </Flex>
        </Flex>
        <Divider />
        <Flex justifyContent={"space-between"} flexWrap="wrap">
          <AllRightsReserved>
            2020 Quikstays. All Rights Reserved
          </AllRightsReserved>
          <FooterText to="/terms-and-conditions">
            Terms and Conditions
          </FooterText>
          <FooterText to="/vending-agreement">Vending Agreement</FooterText>
          <FooterText to="/privacy-policy">Privacy Policy</FooterText>
        </Flex>
      </Container>
    </>
  );
};

export default Footer;
