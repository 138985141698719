import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 40px 49px;
  font-size: 12pt;
  font-family: Avenir, sans-serif;
  @media screen and (max-width: 831px) {
    padding: 21px;
  }
`;

const PrivacyPolicy = () => {
  return (
    <Container>
      <div
        dangerouslySetInnerHTML={{
          __html: `<p style="line-height:1.2;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><strong>Privacy Policy</strong></p>
        <p dir="ltr" style="line-height:1.2;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>
        <p dir="ltr" style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Quikstays is the owner and operator of this website and below is its privacy policy. The policy gives adequate information about the collection, use, and disclosure of personal data when an individual uses the website to transact and/or utilize any of the Quikstays&rsquo; accommodation services. The law requires Quikstays to inform individuals about their rights and Quikstays&rsquo;s obligations to the individual regarding the processing and control of their personal data. Quikstays uses the personal data collected to provide and improve its online marketplace services to the individuals. By using this Service, the individuals agree to the collection and use of information in accordance with this policy.</span></p>
        <p dir="ltr" style="line-height:1.2;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>
        <div align="left" dir="ltr">
            <table style="border:none;border-collapse:collapse; display: block;
            overflow-x: auto;
            white-space: nowrap;">
                <colgroup>
                    <col width="52">
                    <col width="133">
                    <col width="354">
                </colgroup>
                <tbody>
                    <tr style="height:0pt;">
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">S/N</span></p>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Word</span></p>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Meaning</span></p>
                        </td>
                    </tr>
                    <tr style="height:0pt;">
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                            </ol>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">User</span></p>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Any individual or corporate organization that accesses this website using electronic devices such as computers, mobile phones, tablets, etc., for the purposes including but not limited to bookings and making inquiries about online accommodation bookings for short lets and other services facilitated by the marketplace provided by Quikstays. Users shall not include such persons as employees of Quikstays whilst acting in course of their employment or such persons engaged as consultants or otherwise providing services to Quikstays.</span></p>
                        </td>
                    </tr>
                    <tr style="height:0pt;">
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <ol start="2" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                            </ol>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Website</span></p>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The domain name:&nbsp;</span><a href="http://www.quickstays.com" style="text-decoration:none;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#0563c1;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">www.Quikstays.com</span></a><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">.&nbsp;</span></p>
                        </td>
                    </tr>
                    <tr style="height:0pt;">
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <ol start="3" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                            </ol>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Accommodation service</span></p>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The provision of properly furnished properties for habitation by Users.</span></p>
                        </td>
                    </tr>
                    <tr style="height:0pt;">
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <ol start="4" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                            </ol>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Intellectual property</span></p>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">All Content included on the Website, unless uploaded by Users, is the property of Quikstays, our affiliates or other relevant third parties. Such content includes texts, graphics, images, audio, video, software, data compilations, page layout, underlying code and software and any other form of information capable of being stored in a computer that appears on or forms part of this Website.&nbsp;</span></p>
                        </td>
                    </tr>
                    <tr style="height:0pt;">
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <ol start="5" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                            </ol>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Bookings</span></p>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">The reservation of accommodation in advance on the website.</span></p>
                        </td>
                    </tr>
                    <tr style="height:0pt;">
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <ol start="6" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                            </ol>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Service</span></p>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Accommodation service.</span></p>
                        </td>
                    </tr>
                    <tr style="height:0pt;">
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <ol start="7" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                            </ol>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Party</span></p>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Either a User or a Vendor in relevant context.</span></p>
                        </td>
                    </tr>
                    <tr style="height:0pt;">
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <ol start="8" style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
                                <li aria-level="1" dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"><br></li>
                            </ol>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Vendor</span></p>
                        </td>
                        <td style="border-left:solid #000000 0.5pt;border-right:solid #000000 0.5pt;border-bottom:solid #000000 0.5pt;border-top:solid #000000 0.5pt;vertical-align:top;padding:0pt 5.4pt 0pt 5.4pt;overflow:hidden;overflow-wrap:break-word;">
                            <p dir="ltr" style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Avenir,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">A provider of accommodation services advertising on the website.</span></p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


        <p dir="ltr" style="line-height:1.2;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;">&nbsp;</p>
        <p>The type of data we collect from you:</p>
<p><strong>Personal data</strong></p>
<p style="text-align: justify;">While using Quikstays&rsquo;s website, an individual may be required to provide certain personally identifiable information that can be used to contact and identify the individual (otherwise known as &ldquo;personal data&rdquo;). The kind of personal data we collect from you are:</p>
<ul>
<li>First name and last name</li>
<li>Email address</li>
<li>Telephone contact</li>
<li>Address (including, state/province, city, ZIP/Postal code)</li>
<li>Cookies and usage data</li>
</ul>
<p><strong>Usage Data</strong></p>
<p style="text-align: justify;">Quikstays may also collect information on how the website is accessed and used ("Usage Data"). This Usage Data may include information such as the individual&rsquo;s computer Internet Protocol address (e.g. IP address), browser type, browser version, the pages of the website visited by the individual, the time and date of visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
<p><strong>Tracking &amp; Cookies Data</strong></p>
<p style="text-align: justify;">Cookies are files with small amount of data which may include an anonymous unique identifier. They aid identification users of a device used to access the internet. Quikstays uses cookies and related technologies to track an individual&rsquo;s activity on the website and hold certain information.</p>
<p style="text-align: justify;">Cookies are sent to an individual&rsquo;s browser from a website and stored on the individual&rsquo;s device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service. An individual may however set his/her device to refuse all cookies. Although, rejection of cookies, may limit the optimum use of the website.</p>
<p><strong>Use of Data</strong></p>
<p>Quikstays uses the data collect for the following reasons:</p>
<ul>
<li>To give access to the website and the accommodation services advertised on the website by Vendors for online booking</li>
<li>To receive, confirm and process accommodation service bookings, inquiries, feedbacks and complaints</li>
<li>To deliver booking requests to Vendors for processing</li>
<li>To provide customer care and support services</li>
<li>To provide analysis or valuable information aimed at improving the website</li>
<li>To monitor how the website is used</li>
<li>To verify an individual user&rsquo;s identity for security purposes</li>
<li>To record answers to surveys and questionnaires on the website</li>
</ul>
<p><strong>Transfer of Data</strong></p>
<p style="text-align: justify;">Personal Data made available to Quikstays may be transferred to and maintained on computers located outside of a data subject&rsquo;s state, province, country or other governmental jurisdiction where the data protection laws may differ than those from the data subject&rsquo;s jurisdiction.</p>
<p style="text-align: justify;">Please note that Quikstays may transfer the data provided it, including Personal Data, to any country if necessary and process it there. As a result, an individual&rsquo;s consent to this Privacy Policy followed by the submission of such information as personal data is tantamount to an unequivocal agreement to any such transfer.</p>
<p style="text-align: justify;">Quikstays will take every reasonable step to ensure that personal data is treated securely and in accordance with this Privacy Policy and relevant data protection regulations. No transfer of Personal Data will take place to an organization or a country unless there are adequate control in place including the security of personal data and other personal information.</p>
<p><strong>Disclosure of Data</strong></p>
<p style="text-align: justify;">In utmost good faith, Quikstays may disclose your Personal Data for reasons as may become necessary, such as:</p>
<ul>
<li>To comply with an obligation created under a law</li>
<li>To protect and defend the rights or property of Quikstays</li>
<li>To prevent or investigate possible wrongdoing in connection with the website</li>
<li>To protect the personal safety of data subjects who use the website and the general public</li>
<li>To protect against legal liability</li>
</ul>
<p><strong>Individual&rsquo;s Rights</strong></p>
<p>Under relevant laws and regulations, an individual data subject is entitled to the following rights:</p>
<ul>
<li>The right to access:&nbsp;you may at any time request to access your personal data. Upon request, Quikstays will provide a copy of your personal data in a commonly used electronic form.</li>
<li>The right to rectification: you are entitled to obtain rectification of inaccurate personal data and to have incomplete personal data completed.</li>
<li>The right to restriction: of the purposes or use of your personal data.</li>
<li>The right to erasure:&nbsp;under certain circumstances (including processing on the basis of your consent), you may request Quikstays to delete your User Data. Please note that this right is not unconditional. Therefore, an attempt to invoke the right might not lead to an action from Quikstays.</li>
<li>The right to object: to certain processing activities conducted by Quikstays in relation to your personal data, such as processing of your personal data based on our legitimate interest. The right to object also applies to processing of your personal data for direct marketing purposes.</li>
<li>The right to data portability: you are entitled to receive your personal data (or have your personal data directly transmitted to another data controller) in a structured, commonly used and machine-readable format.</li>
<li>The right to withdraw consent at any time.</li>
</ul>
<p><strong>Data Security</strong></p>
<p style="text-align: justify;"><span style="color: #353535;">Although the security of personal data is of utmost importance to us, </span>Quikstays <span style="color: #353535;">however reckons with the fact that there is not a single method of data transmission over the Internet, or method of electronic storage that is fool proof. While </span>Quikstays will <span style="color: #353535;">strive to use commercially acceptable means to protect your Personal Data, it cannot guarantee its absolute security.</span></p>
<p><strong>Service Providers</strong></p>
<p style="text-align: justify;"><span style="color: #353535;">From time to time, </span>Quikstays <span style="color: #353535;">may engage third parties (including companies and individuals) to facilitate the website ("Service Providers"), to operate the website on its behalf, to perform </span>Quikstays&rsquo;s obligations<span style="color: #353535;"> or to assist in analyzing how the website is used. These third parties may in course of the above, have access to Personal Data only for the purpose of performing these tasks and are obligated not to disclose or use it for any other purpose.</span></p>
<p><strong>Links to Other Sites</strong></p>
<p style="text-align: justify;"><span style="color: #353535;">In a bid to provide both Users and Vendors with more resources, the website may contain access (including links) to domain names not operated by </span>Quikstays<span style="color: #353535;">. If an individual clicks on a Vendor&rsquo;s or third-party access, the individual will be directed to such Vendor&rsquo;s third-party's domain name. It is strongly advised that individuals critically review the Privacy Policy of every domain name or links directed through the website as </span>Quikstays <span style="color: #353535;">does not have control over or assume any responsibility for the content of any such third-party domain names or services.</span></p>
<p><strong>Children's Privacy</strong></p>
<p style="text-align: justify;"><span style="color: #353535;">In line with relevant laws and regulations, </span>Quikstays <span style="color: #353535;">only knowingly collect personally identifiable information from persons who have attained the age of 18 years and above. Parents or guardian whose child may have provided </span>Quikstays <span style="color: #353535;">with his Personal Data should kindly contact </span>Quikstays<span style="color: #353535;">. If </span>Quikstays <span style="color: #353535;">ever becomes aware that a person under the age of 18 years has supplied it with his Personal Data without verification of parental consent, it shall take every necessary and reasonable steps to remove such information from its database.</span></p>
<p><strong>Changes to This Privacy Policy</strong></p>
<p style="text-align: justify;"><span style="color: #353535;">In compliance with changing regulations and laws, </span>Quikstays <span style="color: #353535;">may update its Privacy Policy from time to time. If and when it does, it shall notify data subjects via email or display a prominent notice on the website, prior to the change becoming effective. Quikstays shall ensure to review this Policy periodically for updates. Changes to this Privacy Policy are effective once it is posted on this page.</span></p>
<p><strong>Complaints</strong></p>
<p style="text-align: justify;"><span style="color: #353535;">If a data subject is not satisfied with these privacy policies or for such other complaints, he/she can contact us @: </span><a href="mailto:support@quikstays.com" target="_blank" rel="noopener"><span style="color: #0563c1;">support@quikstays.com</span></a></p>
<p style="text-align: justify;"><span style="color: #353535;">If you are not satisfied with the means and manner adopted in resolving your complaint, you may wish to lodge a complaint with the National Information Technology Development Agency (&ldquo;NITDA&rdquo;).&nbsp;</span></p>
<p><strong>Contact Us</strong></p>
<p style="text-align: justify;"><span style="color: #353535;">For any questions about this Privacy Policy, please contact us via: </span><a href="mailto:info@quikstays.com" target="_blank" rel="noopener"><span style="color: #0563c1;">info@quikstays.com</span></a></p>`,
        }}
      />
    </Container>
  );
};

export default PrivacyPolicy;
