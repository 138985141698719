import React from 'react';
import Hero from './Hero'
import HowItWorks from './HowItWorks';
import UseCases from './UseCases';
import NearbyHotels from './NearbyHotels';
import Testimonials from '../Testimonials';

const LandingPage = () => {
  return (
    <>
      <Hero />
      <HowItWorks />
      <UseCases />
      <NearbyHotels />
      <Testimonials />
    </>
  );
}

export default LandingPage;
