import React from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import { Flex } from "rebass";

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 10px #b8b6b2;
  border-radius: 28px;
  padding: 7px;
  width: max-content;
  height: max-content;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #000000;
  &:hover {
    z-index: 1;
  };
`;

const StyledImage = styled.img`
  width: 190px;
  height: 150px;
  border-radius: 6px;
`;


const Text = styled.p`
  color: black;
`

const Hotel = styled.strong`
  color: black;
`

const Image = (props) => {
  return (
    <Flex flexDirection={"column"}>
      <StyledImage src={props.image} />
      <Hotel>{props.name}</Hotel>
      <Text>{props.duration} hours</Text>
      <Text>{props.price ? `₦${props.price}`: `N/A`}</Text>
    </Flex>
  );
};

const Marker = (props) => {
  return (
    <Tooltip
      color="white"
      title={
        <Image
          duration={props.duration}
          price={props.price}
          image={props.image}
          name={props.name}
        />
      }
    >
      <Container>
        <p>{props.price ? `₦${props.price/1000}k`: `N/A`}</p>
      </Container>
    </Tooltip>
  );
};

export default Marker;
